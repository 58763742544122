export const removeExtension = (filename: string): string => {
  const lastDotPosition = filename.lastIndexOf('.');
  if (lastDotPosition === -1) return filename; // No extension found
  return filename.substring(0, lastDotPosition);
};

export const generateShareLink = (
  text: string,
  url: string,
  shareType: 'whatsapp' | 'facebook' | 'x' | 'email' | 'linkedin'
): string => {
  const encodedText = encodeURIComponent(text);
  const encodedUrl = encodeURIComponent(url);

  switch (shareType) {
    case 'whatsapp':
      return `https://api.whatsapp.com/send?text=${encodedText}%20${encodedUrl}`;
    case 'facebook':
      return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${encodedText}`;
    case 'x':
      return `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`;
    case 'email':
      return `mailto:?subject=${encodedText}&body=${encodedText}%20${encodedUrl}`;
    case 'linkedin':
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedText}`;
    default:
      throw new Error('Unsupported share type');
  }
};

export function generateCldUrl(
  originalUrl: string,
  transformationType:
    | 'avatar'
    | 'thumbnail'
    | 'featured_artist'
    | 'featured_track'
    | 'large'
    | 'medium'
    | 'banner'
): string {
  const CLOUDINARY_BASE_URL = 'https://res.cloudinary.com/dfmabcdj7/image/upload/';

  const imagePath = originalUrl.split('/image/upload/')[1];

  let transformation = '';
  switch (transformationType) {
    case 'avatar':
      transformation = 'c_thumb,w_32,h_32/';
      break;
    case 'thumbnail':
      transformation = 'c_fill,w_250,h_250/';
      break;
    case 'featured_track':
      transformation = 'c_fill,w_763,h_500/';
      break;
    case 'featured_artist':
      transformation = 'c_fill,w_373,h_160/';
      break;
    case 'large':
      transformation = 'w_800/';
      break;
    case 'medium':
      transformation = 'w_400/';
      break;
    case 'banner':
      transformation = 'c_scale,w_1200/';
      break;
    default:
      return originalUrl;
  }

  return `${CLOUDINARY_BASE_URL}${transformation}${imagePath}`;
}
