import { useState } from 'react';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import { TrackListResponse } from '../../client';
import UpcomingBlock from './UpcomingBlock';

const swiperStyles = {
  width: '100%',
  height: '100%',
  '--swiper-navigation-color': '#fff',
  '--swiper-navigation-top-offset': '40%',
  '--swiper-navigation-size': '32px',
  '--swiper-navigation-sides-offset': '10px'
};

const swiperBreakpoints = {
  240: {
    slidesPerView: 1,
    spaceBetween: 20
  },
  480: {
    slidesPerView: 2,
    spaceBetween: 20
  },
  640: {
    slidesPerView: 3,
    spaceBetween: 10
  },
  768: {
    slidesPerView: 4,
    spaceBetween: 10
  },
  1024: {
    slidesPerView: 5,
    spaceBetween: 10
  },
  1280: {
    slidesPerView: 6,
    spaceBetween: 10
  },
  1440: {
    slidesPerView: 7,
    spaceBetween: 10
  }
};

interface UpcomingCarouselProps {
  tracks: TrackListResponse[];
}

export const UpcomingCarousel = ({ tracks }: UpcomingCarouselProps) => {
  const [hoveredSlide, setHoveredSlide] = useState<number | null>(null);

  const handleSlideMouseOver = (trackId: number) => {
    setHoveredSlide(trackId);
  };

  const handleSlideMouseOut = () => {
    setHoveredSlide(null);
  };

  return (
    <Box display="flex" width="100%">
      <Swiper
        style={swiperStyles}
        direction={'horizontal'}
        centeredSlides={false}
        navigation={true}
        loop={true}
        modules={[Autoplay, Pagination, Navigation]}
        autoplay={{ delay: 600000, pauseOnMouseEnter: true }}
        slidesPerView={7}
        // freeMode={true}
        breakpoints={swiperBreakpoints}
      >
        {tracks?.map((track, key) => (
          <SwiperSlide
            key={key}
            onMouseOver={() => handleSlideMouseOver(track.id || 0)}
            onMouseOut={handleSlideMouseOut}
          >
            <UpcomingBlock
              track={track}
              hovered={hoveredSlide || 0}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
