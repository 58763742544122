import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

export interface Breadcrumb {
  href: string;
  label: string;
}

export interface BreadcrumbsPanelProps {
  breadcrumbs: Breadcrumb[];
}

export default function BreadcrumbsPanel({ breadcrumbs }: BreadcrumbsPanelProps) {
  const location = useLocation();

  return (
    <Box role="presentation" sx={{ my: 4 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="none" color="inherit" href="/">
          Home
        </Link>
        {breadcrumbs.map((breadcrumb) =>
          location.pathname === breadcrumb.href ? (
            <Link
              key={breadcrumb.label}
              underline="none"
              color="text.primary"
              href={breadcrumb.href}
              aria-current="page"
            >
              {breadcrumb.label}
            </Link>
          ) : (
            <Link key={breadcrumb.label} underline="none" color="inherit" href={breadcrumb.href}>
              {breadcrumb.label}
            </Link>
          )
        )}
      </Breadcrumbs>
    </Box>
  );
}
