import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, styled } from '@mui/material';
import FolderZipIcon from '@mui/icons-material/FolderZip';

type ZipDropZoneProps = {
  onFileUpload: (acceptedFiles: File[]) => void;
};

const ZipDragZoneBox = styled(Box)({
  border: '2px dashed rgba(255, 255, 255, 0.2)',
  borderRadius: '10px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)'
  }
});

const ZipDropZone = ({ onFileUpload }: ZipDropZoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFileUpload(acceptedFiles);
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/zip': ['.zip', '.7z', '.rar']
    }
  });

  return (
    <ZipDragZoneBox {...getRootProps()}>
      <input {...getInputProps()} />
      <FolderZipIcon sx={{ fontSize: 40, color: 'action.active' }} />
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        {isDragActive ? 'Drop the files here ...' : 'Click to upload or drag and drop a zip file'}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        zip, rar, 7z (max. 4000MB)
      </Typography>
    </ZipDragZoneBox>
  );
};

export default ZipDropZone;
