import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';

import { KeyPicker } from '../KeyPicker';
import { useMusicUpload } from '../../context/MusicUploadContext';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Track, TracksService } from '../../client';
import { useEffect, useState } from 'react';

export default function UpdateBpmAndScale() {
  const { formMethods, track, setTrack } = useMusicUpload();
  const [autoDetectionEnabled, setAutoDetectionEnabled] = useState(true);

  const { data: updatedTrack } = useQuery(
    `track-${track?.id}`,
    () => TracksService.getTrackDetailsWizardApiTracksTrackIdWizardGet(Number(track?.id) || -1),
    {
      enabled: !!track?.id,
      onSuccess: (data: Track) => {
        setTrack(data);
      },
      refetchInterval: 1000
    }
  );

  useEffect(() => {
    if (updatedTrack) {
      formMethods.setValue('bpm', updatedTrack.bpm);
      formMethods.setValue('scale', updatedTrack.scale);
    }
  }, [updatedTrack]);

  const watchBpm = formMethods.watch('bpm');
  const watchScale = formMethods.watch('scale');

  return (
    <Box>
      <Typography variant="h6" color="#ffffffee" gutterBottom>
        Set BPM and Scale
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4 }} color="#ffffff7f">
        The system automatically detects the BPM and key for your track. You can change them if you
        want.
      </Typography>
      {!updatedTrack?.bpm && !watchBpm && autoDetectionEnabled ? (
        <Box display="flex" gap={2}>
          <CircularProgress size={20} thickness={5} sx={{ color: '#ffffff7f', mb: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 4 }} color="#ffffff7f">
            Detecting bpm...
          </Typography>
        </Box>
      ) : (
        <Box sx={{ maxWidth: 200, minWidth: 100, width: '100%', mb: 3 }}>
          <Controller
            name="bpm"
            control={formMethods.control}
            render={({ field: { value, onChange } }) => (
              <TextField
                type="number"
                label="BPM"
                inputProps={{ min: 0, max: 500, step: 1 }}
                value={value}
                onChange={onChange}
                sx={{ width: '100%' }}
                required
              />
            )}
          />
        </Box>
      )}
      {!updatedTrack?.bpm && !watchScale && autoDetectionEnabled ? (
        <Box display="flex" gap={2}>
          <CircularProgress size={20} thickness={5} sx={{ color: '#ffffff7f', mb: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 4 }} color="#ffffff7f">
            Detecting key...
          </Typography>
        </Box>
      ) : (
        <Controller
          name="scale"
          control={formMethods.control}
          render={({ field: { value, onChange } }) => (
            <KeyPicker handleScaleSelect={onChange} selectedKey={value} />
          )}
        />
      )}
      {!watchBpm && !watchScale && autoDetectionEnabled && (
        <Button sx={{ mt: 2 }} onClick={() => setAutoDetectionEnabled(false)}>
          Skip auto-detection
        </Button>
      )}
    </Box>
  );
}
