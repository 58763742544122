import { useContext } from 'react';
import { PlaylistContext } from '../context/PlaylistProvider';

export const usePlaylist = () => {
  const context = useContext(PlaylistContext);
  if (context === undefined) {
    throw new Error('usePlaylist must be used within a PlaylistProvider');
  }
  return context;
};
