import React from 'react';
import { Box, Typography, LinearProgress, IconButton, CircularProgress } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Link } from 'react-router-dom';

type UploadProgressProps = {
  fileName: string;
  fileSize: number;
  progress: number;
  onCancel: () => void;
  uploaded: boolean;
  uploadAfter?: boolean;
  uploadingAfterSubmit?: boolean;
  url?: string;
};

const UploadProgress: React.FC<UploadProgressProps> = ({
  fileName,
  fileSize,
  progress,
  onCancel,
  uploaded,
  uploadAfter,
  uploadingAfterSubmit,
  url
}) => {
  const convertBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: 2 }}>
      <InsertDriveFileIcon sx={{ mr: 1 }} />
      {!url ? (
        <Box sx={{ width: '100%', mr: 1 }}>
          <Typography variant="body2" noWrap>
            {fileName}
          </Typography>
          {uploadAfter ? (
            <Typography variant="caption">
              {convertBytes(fileSize)} -{' '}
              {uploaded
                ? 'Uploaded'
                : uploadingAfterSubmit
                  ? 'Uploading...'
                  : 'Click submit to start uploading'}
            </Typography>
          ) : (
            <Typography variant="caption">
              {convertBytes(fileSize)} -{' '}
              {uploaded ? (
                'Uploaded'
              ) : progress < 100 ? (
                <Box display="inline-flex" flexDirection="row" gap={1}>
                  <span>Loading</span>
                </Box>
              ) : (
                <Box display="inline-flex" flexDirection="row" gap={1}>
                  <span>Processing file</span>
                  <CircularProgress size={16} thickness={4.5} color="primary" />
                </Box>
              )}
            </Typography>
          )}
          {!uploaded && (
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ height: 5, borderRadius: 1, mt: 1 }}
            />
          )}
        </Box>
      ) : (
        <Box sx={{ width: '100%', mr: 1 }}>
          <Link to={url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
            <Typography variant="body2" noWrap color="text.primary">
              {fileName}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {url}
            </Typography>
          </Link>
        </Box>
      )}
      {!uploaded && (
        <IconButton onClick={onCancel}>
          <CancelIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default UploadProgress;
