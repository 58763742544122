import saveAs from 'file-saver';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useConfirm } from 'material-ui-confirm';

import { Box, IconButton, TablePagination, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useSnackBarContext } from '../context/SnackBarContext';

import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import { TrackDownloadsService, TrackListResponse } from '../client';
import { AudioWavePlayer } from './AudioWavePlayer';
import { Loader } from './Loader';
import { useAudioWavePlayer } from '../context/AudioWavePlayerContext';
import slugify from 'react-slugify';

const ROWS_PER_PAGE = 5;

export default function ProfileDownloadsList() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const queryClient = useQueryClient();
  const { isPlaying: isCurrentPlaying, setIsPlaying: setIsCurrentPlaying } = useAudioWavePlayer();

  const confirm = useConfirm();

  const { createSnackBar } = useSnackBarContext();

  const { data: track_downloads, isLoading } = useQuery(
    ['track-downloads', page, rowsPerPage],
    () => TrackDownloadsService.getTrackDownloadsApiTrackDownloadsGet(page, rowsPerPage),
    {
      keepPreviousData: true
    }
  );
  if (isLoading) return <Loader />;

  if (!track_downloads || track_downloads.items.length === 0) return <div>No downloads yet</div>;

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page + 1);
  };

  const handleOnRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleTrackRemove = (track: TrackListResponse) => {
    confirm({
      description: `Are you sure you want to remove ${track.name} from your downloads?`,
      title: 'Remove download',
      confirmationText: 'Yes',
      cancellationText: 'No'
    })
      .then(() => {
        TrackDownloadsService.deleteTrackDownloadApiTrackDownloadsDelete(track.id).then(() => {
          queryClient.invalidateQueries('track-downloads');
        });
      })
      .catch(() => {
        console.log('cancelled');
      });
  };

  const getGenres = (track: TrackListResponse) => {
    if (!track.genres) return '';
    return track.genres.map((genre) => genre.name).join(', ');
  };

  const sendToClipboard = (track: TrackListResponse) => {
    const trackDetailsUrl = `${window.location.origin}/track/${track.id}/${slugify(track.name)}`;
    navigator.clipboard.writeText(trackDetailsUrl);
    createSnackBar({ content: 'Link copied to clipboard', autoHide: true, severity: 'info' });
  };

  const handlePlayPause = (track_id: number) => {
    if (isCurrentPlaying !== track_id) {
      setIsCurrentPlaying(track_id);
    }
  };

  return (
    <TableContainer sx={{ mb: 4 }} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">TITLE / ARTISTS</TableCell>
            <TableCell align="left">GENRE / KEY</TableCell>
            <TableCell align="left">BPM</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {track_downloads.items.map((track_download) => (
            <TableRow
              key={track_download.id}
              hover
              role="checkbox"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">
                <AudioWavePlayer
                  src={track_download.track.preview_url || ''}
                  track_id={track_download.track.id}
                  track_name={track_download.track.name}
                  track_artist={track_download.track.user?.profile_name}
                  track_artist_id={track_download.track.user?.id}
                  track_image={track_download.track.image_url}
                  handlePlayPause={handlePlayPause}
                />
              </TableCell>
              <TableCell align="left">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box>{getGenres(track_download.track)}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {track_download.track.scale}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body2" color="text.secondary">
                  {track_download.track.bpm} BPM
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                  <Tooltip title="Add to favorites">
                    <IconButton aria-label="favorite">
                      <FavoriteBorderOutlinedIcon sx={{ width: 18, height: 18 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Send" onClick={() => sendToClipboard(track_download.track)}>
                    <IconButton aria-label="send">
                      <SendIcon sx={{ width: 18, height: 18 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download">
                    <IconButton
                      aria-label="download"
                      onClick={() =>
                        saveAs(
                          track_download.track?.data_url as any,
                          `${track_download.user?.profile_name}-${track_download.track.name}`
                        )
                      }
                    >
                      <DownloadIcon sx={{ width: 18, height: 18 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove from downloads">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleTrackRemove(track_download.track)}
                    >
                      <DeleteIcon sx={{ width: 18, height: 18 }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={track_downloads.total || 0}
        rowsPerPage={rowsPerPage}
        page={(track_downloads.page || 1) - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleOnRowsPerPageChange}
      />
    </TableContainer>
  );
}
