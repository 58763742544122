import axios from 'axios';

import { useState } from 'react';
import { useMutation } from 'react-query';

import { Box, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';

import ImageDropZone from '../ImageDropZone';
import UploadProgress from '../UploadProgress';
import { Controller } from 'react-hook-form';
import { useAddUpcomingProject } from '../../context/UpcomingProjectContext';

export default function UploadImage() {
  const { track, setTrack, formMethods, setApiErrors, setIsLoading } = useAddUpcomingProject();
  const [imageUploadProgress, setImageUploadProgress] = useState<number>(0);
  const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false);

  const confirm = useConfirm();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = formMethods;

  const uploadImageMutation = useMutation(
    (newTrack: { image: File; track_id?: number }) => {
      return axios.put(`/api/tracks/${newTrack.track_id}/upload_image`, newTrack, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0;
          setImageUploadProgress(percentCompleted);
          console.log(percentCompleted);
        }
      });
    },
    {
      onSuccess: (response) => {
        setIsImageUploaded(true);
        setTrack(response.data);
        setIsLoading(false);
      },
      onError: async (error: any) => {
        console.log(error);
        setIsImageUploaded(false);
        setApiErrors({ ...errors, audio: 'Error uploading audio file' });
        setIsLoading(false);
      }
    }
  );

  const handleImageUpload = async (acceptedFiles: File[]) => {
    setIsLoading(true);
    setImageUploadProgress(0);
    setIsImageUploaded(false);
    setTrack(null);

    setValue('image', acceptedFiles[0]);
    await handleSubmit(async (data) => {
      setApiErrors({ ...errors, image: '' });
      await uploadImageMutation.mutateAsync({ image: data.image, track_id: data.id });
    })();
  };

  const onImageUploadCancel = () => {
    confirm({
      description: 'Are you sure you want to cancel uploading this image file?',
      title: 'Cancel Upload',
      confirmationText: 'Yes',
      cancellationText: 'No'
    })
      .then(() => {
        reset({ image: null });
        setIsImageUploaded(false);
        setImageUploadProgress(0);
        setIsLoading(false);
      })
      .catch(() => {
        console.log('cancelled');
      });
  };
  return (
    <Box>
      <Typography variant="h6" color="#ffffffee" gutterBottom>
        Upload Image
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4 }} color="#ffffff7f">
        This will be used as the cover art of your track.
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 4, mb: 2 }}>
        Upload Image File - optional
      </Typography>
      <Controller
        name="image"
        control={control}
        defaultValue={null}
        render={({ field: { value } }) => (
          <>
            <ImageDropZone onImageUpload={handleImageUpload} />
            {(value || track?.image_url) && (
              <UploadProgress
                fileName={value?.name || track?.image_url?.split('/').pop() || ''}
                fileSize={value?.size}
                progress={!!track?.image_url ? 100 : imageUploadProgress}
                onCancel={onImageUploadCancel}
                uploaded={(imageUploadProgress === 100 && isImageUploaded) || !!track?.image_url}
                url={imageUploadProgress === 0 ? track?.image_url : undefined}
              />
            )}
          </>
        )}
      />
      {errors.image && errors.image.type === 'FILE_SIZE' && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {String(errors.image.message)}
        </Typography>
      )}
    </Box>
  );
}
