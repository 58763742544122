import { Box, Button, Container, Typography } from '@mui/material';
import ProfileUploadsList from '../../../components/ProfileUploadsList';
import BreadcrumbsPanel from '../../../components/BreadcrumbsPanel';
import { useLocation } from 'react-router-dom';

export default function ProfileUploads() {
  const location = useLocation();
  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <BreadcrumbsPanel breadcrumbs={[{ label: 'Uploads', href: location.pathname }]} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Uploads
        </Typography>
        <Button variant="contained" color="primary" href="/profile/upload-music">
          Upload Music
        </Button>
      </Box>
      <ProfileUploadsList />
    </Container>
  );
}
