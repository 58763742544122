import { Stars, Verified } from '@mui/icons-material';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { useProfile } from '../../hooks/useProfile';
import { useCurrentSubscription } from '../../hooks/useCurrentSubscription';
import { useCancelSubscription } from '../../hooks/useCancelSubscription';
import { useConfirm } from 'material-ui-confirm';
import { Loader } from '../Loader';
import { useState } from 'react';
import { CancelDialog } from './CancelDialog';

const planIcons: { [key: string]: React.ReactNode } = {
  free: null,
  premium: <Verified sx={{ fontSize: '1rem', color: 'text.primary' }} />,
  pro: <Stars sx={{ fontSize: '1rem', color: 'text.primary' }} />
};

const plansReadables: { [key: string]: string } = {
  free: 'Basic (Free)',
  premium: 'Advanced',
  pro: 'Pro - Artist'
};

export const BillingSettings = () => {
  const [unsubscribeDialogOpen, setUnsubscribeDialogOpen] = useState(false);
  
  const { data: profile, isLoading: isProfileLoading } = useProfile();
  const { data: subscription, isLoading: isSubscriptionLoading } = useCurrentSubscription();

  const isDataLoading = isProfileLoading || isSubscriptionLoading;

  const confirm = useConfirm();
  const cancelSubscriptionMutation = useCancelSubscription();

  const handleCancelSubscription = async () => {
    try {
      await confirm({
        title: 'Cancel Subscription',
        description: 'Are you sure you want to cancel your subscription?',
        confirmationText: 'Yes, Cancel Subscription',
        cancellationText: 'No, Keep Subscription',
        dialogProps: {
          disableEscapeKeyDown: true
        }
      });
      triggerUnsubscribeDialog();
    } catch (error) {
      console.log('Cancel Subscription Error:', error);
    }
  };

  const timestampToDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleDateString();
  };

  const triggerUnsubscribeDialog = () => {
    setUnsubscribeDialogOpen(!unsubscribeDialogOpen);
  };

  return (
    <Paper sx={{ p: 5 }}>
      <Stack direction="column" spacing={1}>
        <Typography variant="h6" gutterBottom>
          Subscriptions
        </Typography>
        {isDataLoading ? (
          <Loader />
        ) : (
          <>
            <CancelDialog
              open={unsubscribeDialogOpen}
              handleClose={triggerUnsubscribeDialog}
              cancelSubscriptionMutation={cancelSubscriptionMutation}
            />
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 'bold' }}>
                Your Current Plan:
              </Typography>
              {planIcons[profile?.plan || 'free']}
              <Typography variant="subtitle1" color="text.secondary">
                {plansReadables[profile?.plan || 'free']}
              </Typography>
            </Stack>
            {subscription?.current_period_end && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 'bold' }}>
                  Current Period End:
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {timestampToDate(subscription?.current_period_end || 0)}
                </Typography>
              </Stack>
            )}
            {profile?.plan === 'free' ? (
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2, width: 'fit-content' }}
                href="/pricing"
              >
                Upgrade Plan
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="error"
                sx={{ mt: 2, width: 'fit-content' }}
                onClick={handleCancelSubscription}
                disabled={cancelSubscriptionMutation.isLoading}
              >
                Cancel Subscription
              </Button>
            )}
          </>
        )}
      </Stack>
    </Paper>
  );
};
