/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_complete_multipart_upload_api_tracks__track_id__complete_multipart_upload_post } from '../models/Body_complete_multipart_upload_api_tracks__track_id__complete_multipart_upload_post';
import type { Body_initiate_multipart_upload_api_tracks__track_id__initiate_multipart_upload_post } from '../models/Body_initiate_multipart_upload_api_tracks__track_id__initiate_multipart_upload_post';
import type { Body_upload_image_api_tracks__track_id__upload_image_put } from '../models/Body_upload_image_api_tracks__track_id__upload_image_put';
import type { Body_upload_track_api_tracks_upload_audio_post } from '../models/Body_upload_track_api_tracks_upload_audio_post';
import type { Body_upload_zip_api_tracks__track_id__upload_zip_put } from '../models/Body_upload_zip_api_tracks__track_id__upload_zip_put';
import type { Body_upload_zip_chunk_api_tracks__track_id__upload_zip_chunk_put } from '../models/Body_upload_zip_chunk_api_tracks__track_id__upload_zip_chunk_put';
import type { Page_TrackListResponse_ } from '../models/Page_TrackListResponse_';
import type { Track } from '../models/Track';
import type { TrackArtist } from '../models/TrackArtist';
import type { TrackInfoUpdateRequest } from '../models/TrackInfoUpdateRequest';
import type { TrackListResponse } from '../models/TrackListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TracksService {

    /**
     * Get Most Popular Tracks
     * @returns TrackListResponse Successful Response
     * @throws ApiError
     */
    public static getMostPopularTracksApiTracksGetMostPopularTracksGet(): CancelablePromise<Array<TrackListResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracks/get_most_popular_tracks',
        });
    }

    /**
     * Get Most Recent Tracks
     * @returns TrackListResponse Successful Response
     * @throws ApiError
     */
    public static getMostRecentTracksApiTracksGetMostRecentTracksGet(): CancelablePromise<Array<TrackListResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracks/get_most_recent_tracks',
        });
    }

    /**
     * Get Most Downloaded Tracks
     * @returns TrackListResponse Successful Response
     * @throws ApiError
     */
    public static getMostDownloadedTracksApiTracksGetMostDownloadedTracksGet(): CancelablePromise<Array<TrackListResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracks/get_most_downloaded_tracks',
        });
    }

    /**
     * Upload Track
     * @param formData
     * @param trackId
     * @returns Track Successful Response
     * @throws ApiError
     */
    public static uploadTrackApiTracksUploadAudioPost(
        formData: Body_upload_track_api_tracks_upload_audio_post,
        trackId?: number,
    ): CancelablePromise<Track> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tracks/upload_audio',
            query: {
                'track_id': trackId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Zip
     * @param trackId
     * @param formData
     * @returns Track Successful Response
     * @throws ApiError
     */
    public static uploadZipApiTracksTrackIdUploadZipPut(
        trackId: number,
        formData: Body_upload_zip_api_tracks__track_id__upload_zip_put,
    ): CancelablePromise<Track> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/tracks/{track_id}/upload_zip',
            path: {
                'track_id': trackId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Initiate Multipart Upload
     * @param trackId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static initiateMultipartUploadApiTracksTrackIdInitiateMultipartUploadPost(
        trackId: number,
        formData: Body_initiate_multipart_upload_api_tracks__track_id__initiate_multipart_upload_post,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tracks/{track_id}/initiate_multipart_upload',
            path: {
                'track_id': trackId,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Zip Chunk
     * @param trackId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static uploadZipChunkApiTracksTrackIdUploadZipChunkPut(
        trackId: number,
        formData: Body_upload_zip_chunk_api_tracks__track_id__upload_zip_chunk_put,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/tracks/{track_id}/upload_zip_chunk',
            path: {
                'track_id': trackId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Complete Multipart Upload
     * @param trackId
     * @param formData
     * @returns Track Successful Response
     * @throws ApiError
     */
    public static completeMultipartUploadApiTracksTrackIdCompleteMultipartUploadPost(
        trackId: number,
        formData: Body_complete_multipart_upload_api_tracks__track_id__complete_multipart_upload_post,
    ): CancelablePromise<Track> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tracks/{track_id}/complete_multipart_upload',
            path: {
                'track_id': trackId,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Image
     * @param trackId
     * @param formData
     * @returns Track Successful Response
     * @throws ApiError
     */
    public static uploadImageApiTracksTrackIdUploadImagePut(
        trackId: number,
        formData: Body_upload_image_api_tracks__track_id__upload_image_put,
    ): CancelablePromise<Track> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/tracks/{track_id}/upload_image',
            path: {
                'track_id': trackId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Tracks
     * @param page
     * @param size
     * @returns Page_TrackListResponse_ Successful Response
     * @throws ApiError
     */
    public static getTracksApiTracksGet(
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_TrackListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracks',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Track Details
     * @param trackId
     * @returns TrackListResponse Successful Response
     * @throws ApiError
     */
    public static getTrackDetailsApiTracksTrackIdGet(
        trackId: number,
    ): CancelablePromise<TrackListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracks/{track_id}',
            path: {
                'track_id': trackId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Track Info
     * @param trackId
     * @param requestBody
     * @returns Track Successful Response
     * @throws ApiError
     */
    public static updateTrackInfoApiTracksTrackIdPut(
        trackId: number,
        requestBody: TrackInfoUpdateRequest,
    ): CancelablePromise<Track> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/tracks/{track_id}',
            path: {
                'track_id': trackId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Track
     * @param trackId
     * @returns Track Successful Response
     * @throws ApiError
     */
    public static deleteTrackApiTracksTrackIdDelete(
        trackId: number,
    ): CancelablePromise<Track> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/tracks/{track_id}',
            path: {
                'track_id': trackId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Track Details Wizard
     * @param trackId
     * @returns TrackListResponse Successful Response
     * @throws ApiError
     */
    public static getTrackDetailsWizardApiTracksTrackIdWizardGet(
        trackId: number,
    ): CancelablePromise<TrackListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracks/{track_id}/wizard',
            path: {
                'track_id': trackId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Track Genres
     * @param trackId
     * @param requestBody
     * @returns Track Successful Response
     * @throws ApiError
     */
    public static updateTrackGenresApiTracksTrackIdGenresPut(
        trackId: number,
        requestBody: Array<string>,
    ): CancelablePromise<Track> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/tracks/{track_id}/genres',
            path: {
                'track_id': trackId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Artist
     * @param artistId
     * @returns TrackArtist Successful Response
     * @throws ApiError
     */
    public static getArtistApiTracksArtistArtistIdGet(
        artistId: number,
    ): CancelablePromise<TrackArtist> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracks/artist/{artist_id}',
            path: {
                'artist_id': artistId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Artist Tracks
     * @param artistId
     * @param page
     * @param size
     * @returns Page_TrackListResponse_ Successful Response
     * @throws ApiError
     */
    public static getArtistTracksApiTracksArtistArtistIdTracksGet(
        artistId: number,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_TrackListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracks/artist/{artist_id}/tracks',
            path: {
                'artist_id': artistId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upcoming Project
     * @param requestBody
     * @returns Track Successful Response
     * @throws ApiError
     */
    public static upcomingProjectApiTracksUpcomingProjectPost(
        requestBody: TrackInfoUpdateRequest,
    ): CancelablePromise<Track> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tracks/upcoming-project',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Upcoming Tracks
     * @returns TrackListResponse Successful Response
     * @throws ApiError
     */
    public static getUpcomingTracksApiTracksUpcomingProjectsGet(): CancelablePromise<Array<TrackListResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracks/upcoming/projects',
        });
    }

    /**
     * Get Upcoming Tracks
     * @param page
     * @param size
     * @returns Page_TrackListResponse_ Successful Response
     * @throws ApiError
     */
    public static getUpcomingTracksApiTracksUserUpcomingProjectsGet(
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_TrackListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracks/user/upcoming/projects',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
