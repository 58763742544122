import { Box, Autocomplete, TextField } from '@mui/material';
import { musicalKeys } from './musicalKeys';

interface KeyPickerProps {
  selectedKey: string;
  handleScaleSelect: (scale: string) => void;
}

export const KeyPicker = ({ selectedKey, handleScaleSelect }: KeyPickerProps) => {
  return (
    <Box>
      <Autocomplete
        value={selectedKey}
        onChange={(event, newValue) => handleScaleSelect(newValue || '')}
        options={musicalKeys}
        renderInput={(params) => <TextField {...params} required label="Select Key" />}
      />
    </Box>
  );
};
