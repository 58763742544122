import { useMutation, useQueryClient } from 'react-query';
import { PaymentsService } from '../client';
import { useSnackBarContext } from '../context/SnackBarContext';

export const useCancelSubscription = () => {
  const { createSnackBar } = useSnackBarContext();
  const queryClient = useQueryClient();
  const mutation = useMutation(PaymentsService.cancelSubscriptionApiPaymentsCancelSubscriptionGet, {
    onSuccess: () => {
      queryClient.invalidateQueries('currentSubscription');
      queryClient.invalidateQueries('profile');
      createSnackBar({
        content: 'Subscription cancelled successfully',
        severity: 'success',
        autoHide: true
      });
    }
  });
  return mutation;
};
