import { Box, Card, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import { Download, Lyrics, MusicNote, Upload } from '@mui/icons-material';

function StepIcon(props: { icon: JSX.Element, text: string }) {
  return (
    <Stack alignItems="center" gap={2}>
      <Box sx={{borderRadius: "100%", width: 100, height: 100, backgroundColor: "#d5005a", display: "flex", alignItems: "center", justifyContent: "center"}}>
        {props.icon}
      </Box>
      <Typography variant="body1" sx={{ mt: 2, mb: 2, textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
        {props.text}
      </Typography>
    </Stack>
  );
}

export default function InfoSection() {
  return (
    <Box sx={{ backgroundColor: 'rgba(0,0,0,0.2)'}}>
      <Container maxWidth="md" sx={{ mt: 5, mb: 5, display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h4"
          sx={{
            mt: 4,
            mb: 8,
            textAlign: 'center',
            verticalAlign: 'middle'
          }}
        >
          How does MUZA work?
        </Typography>
        <iframe
          width="853"
          style={{maxWidth: "100%"}}
          height="480"
          src={`https://www.youtube.com/embed/hgC6S0xUV4g`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="How does Muza work?"
        />

      </Container>
    </Box>
  );
}
