import axios from 'axios';

import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { Alert, Box, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useMusicUpload } from '../../context/MusicUploadContext';

import AudioDropZone from '../AudioDropZone';
import UploadProgress from '../UploadProgress';
import { Controller } from 'react-hook-form';

export default function UploadAudio() {
  const { track, setTrack, apiErrors, setApiErrors, formMethods, setIsLoading } = useMusicUpload();
  // const [audio, setAudio] = useState<File | null>(null);
  const [audioUploadProgress, setAudioUploadProgress] = useState<number>(0);
  const [isAudioUploaded, setIsAudioUploaded] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = formMethods;

  const audio = watch('audio');

  const confirm = useConfirm();

  const uploadAudioMutation = useMutation(
    (newTrack: { audio: File; track_id?: number }) => {
      return axios.post(
        `/api/tracks/upload_audio?track_id=${newTrack.track_id || 0}`,
        { audio: newTrack.audio },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: '*/*'
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = progressEvent.total
              ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
              : 0;
            setAudioUploadProgress(percentCompleted);
          }
        }
      );
    },
    {
      onSuccess: (response) => {
        setIsAudioUploaded(true);
        setTrack(response.data);
        setIsLoading(false);
      },
      onError: async (error: any) => {
        console.log(error);
        setIsAudioUploaded(false);
        setApiErrors({ ...errors, audio: 'Error uploading audio file' });
        setIsLoading(false);
      }
    }
  );
  const handleFileUpload = async (acceptedFiles: File[]) => {
    setIsLoading(true);
    setAudioUploadProgress(0);
    setIsAudioUploaded(false);
    setTrack(null);

    setValue('audio', acceptedFiles[0]);
    await handleSubmit(async (data) => {
      setApiErrors({ ...errors, audio: '' });
      await uploadAudioMutation.mutateAsync({ audio: data.audio, track_id: data.id });
    })();
  };

  const onAudioUploadCancel = () => {
    confirm({
      description: 'Are you sure you want to cancel uploading this audio file?',
      title: 'Cancel Upload',
      confirmationText: 'Yes',
      cancellationText: 'No'
    })
      .then(() => {
        reset({ audio: null });
        setTrack(null);
        setIsAudioUploaded(false);
        setAudioUploadProgress(0);
        setIsLoading(false);
      })
      .catch(() => {
        console.log('cancelled');
      });
  };

  console.log('errors:', errors);

  return (
    <Box>
      <Typography variant="h6" color="#ffffffee" gutterBottom>
        Upload Audio Preview
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4 }} color="#ffffff7f">
        The audio file you upload will be accessible to other artists on our platform. It serves as
        a foundation for collaboration, inspiring others to contribute their unique talents and
        together create a masterpiece.
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Audio File
      </Typography>
      <Controller
        name="audio"
        control={control}
        defaultValue={null}
        render={({ field: { value } }) => (
          <>
            <AudioDropZone onFileUpload={handleFileUpload} />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              {(audio || track?.preview_url) && (
                <UploadProgress
                  fileName={audio?.name || track?.name}
                  fileSize={audio?.size}
                  progress={track?.preview_url ? 100 : audioUploadProgress}
                  onCancel={onAudioUploadCancel}
                  uploaded={
                    (audioUploadProgress === 100 && isAudioUploaded) || !!track?.preview_url
                  }
                  url={audioUploadProgress === 0 ? track?.preview_url : undefined}
                />
              )}
            </Box>
          </>
        )}
      />

      {apiErrors.audio && (
        <Alert sx={{ my: 1 }} variant="outlined" severity="error">
          {apiErrors.audio}
        </Alert>
      )}
      {errors.audio && errors.audio.type === 'FILE_SIZE' && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {String(errors.audio.message)}
        </Typography>
      )}
    </Box>
  );
}
