import saveAs from 'file-saver';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { Box, IconButton, TablePagination, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from 'material-ui-confirm';
import { TrackListResponse, TracksService } from '../../client';
import { Loader } from '../Loader';

const ROWS_PER_PAGE = 5;

export default function UpcomingProjectsList() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const queryClient = useQueryClient();

  const confirm = useConfirm();

  const { data: tracks, isLoading } = useQuery(
    ['upcoming-projects', page, rowsPerPage],
    () => TracksService.getUpcomingTracksApiTracksUserUpcomingProjectsGet(page, rowsPerPage),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
  if (isLoading) return <Loader />;

  if (!tracks || tracks.items.length === 0) return <div>No tracks</div>;

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page + 1);
  };

  const handleOnRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleTrackRemove = (track: TrackListResponse) => {
    confirm({
      description: `Are you sure you want to remove ${track.name}?`,
      title: 'Remove track',
      confirmationText: 'Yes',
      cancellationText: 'No'
    })
      .then(() => {
        TracksService.deleteTrackApiTracksTrackIdDelete(track.id).then(() => {
          queryClient.invalidateQueries('upcoming-projects');
        });
      })
      .catch(() => {
        console.log('cancelled');
      });
  };


  return (
    <TableContainer sx={{ mb: 4 }} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Image</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tracks.items.map((track) => (
            <TableRow
              key={track.id}
              hover
              role="checkbox"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">
                {track.name}
              </TableCell>
              <TableCell align="left">
                <img src={track.image_url} alt={track.name} style={{ width: 50, height: 50 }} />
              </TableCell>
              <TableCell align="right">
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Tooltip title="Delete track">
                    <IconButton aria-label="delete" onClick={() => handleTrackRemove(track)}>
                      <DeleteIcon sx={{ width: 18, height: 18 }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tracks.total || 0}
        rowsPerPage={rowsPerPage}
        page={(tracks.page || 1) - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleOnRowsPerPageChange}
      />
    </TableContainer>
  );
}
