// FeatureRow.tsx
import { Grid, Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { FeatureType } from './PlanCard';

interface FeatureRowProps {
  feature: FeatureType;
}

const FeatureRow: React.FC<FeatureRowProps> = ({ feature }) => (
  <Grid
    container
    justifyContent="space-around"
    alignItems="center"
    paddingY={3}
    sx={{
      borderBottom: '1px solid #d3d3d31f'
    }}
  >
    <Grid item xs={12} md={3}>
      <Typography
        variant="subtitle1"
        textAlign={{ xs: 'center', md: 'right' }}
        fontWeight="bold"
      >
        {feature.optionName}
      </Typography>
    </Grid>
    <Grid item xs={4} md={3} sx={{ textAlign: 'center' }}>
      {typeof feature.basic === 'number' ? (
        <Typography variant="subtitle1" fontWeight="bold" color="text.secondary">
          {feature.basic}
        </Typography>
      ) : feature.basic ? (
        <Check color="success" />
      ) : (
        <Close color="error" />
      )}
    </Grid>
    <Grid item xs={4} md={3} sx={{ textAlign: 'center' }}>
      {typeof feature.advanced === 'number' ? (
        <Typography variant="subtitle1" fontWeight="bold" color="text.secondary">
          {feature.advanced}
        </Typography>
      ) : feature.advanced ? (
        <Check color="success" />
      ) : (
        <Close color="error" />
      )}
    </Grid>
    <Grid item xs={4} md={3} sx={{ textAlign: 'center' }}>
      {['number', 'string'].includes(typeof feature.pro) ? (
        <Typography variant="subtitle1" fontWeight="bold" color="text.secondary">
          {feature.pro}
        </Typography>
      ) : feature.pro ? (
        <Check color="success" />
      ) : (
        <Close color="error" />
      )}
    </Grid>
  </Grid>
);

export default FeatureRow;
