import * as yup from 'yup';

export const audioUploadSchema = yup
  .object({
    audio: yup
      .mixed()
      .required('Audio file is required')
      .test(
        'FILE_SIZE',
        'File is too large. Please upload a file smaller than 150MB.',
        (value: any) => {
          return value && value.size <= 150 * 1024 * 1024;
        }
      )
      .test(
        'FILE_TYPE',
        'Invalid file type. Please upload an audio file.',
        (value: any) => value && value.type.startsWith('audio/')
      )
  })
  .required();

export const zipUploadSchema = yup
  .object({
    zip_file: yup
      .mixed()
      .required('A zip file is required')
      .test(
        'FILE_SIZE',
        'File is too large. Please upload a file smaller than 4000MB.',
        (value: any) => {
          return value && value.size <= 4000 * 1024 * 1024;
        }
      )
  })
  .required();

export const upcomingTrackSchema = yup
  .object({
    name: yup.string().required('Track name is required'),
  })
  .required();

export const trackInformationSchema = yup
  .object({
    name: yup.string().required('Track name is required'),
    about: yup.string().nullable(),
    genres: yup.array().of(yup.string())
  })
  .required();

export const bpmAndScaleSchema = yup
  .object({
    bpm: yup.number().required('BPM is required'),
    scale: yup.string().required('Scale is required')
  })
  .required();

export const imageUploadSchema = yup.object({
  image: yup
    .mixed()
    .test(
      'FILE_SIZE',
      'File is too large. Please upload a file smaller than 5MB.',
      (value: any) => {
        if (value === null) {
          return true;
        }
        return value && typeof value.size === 'number' && value.size <= 5 * 1024 * 1024;
      }
    )
    .nullable()
});
