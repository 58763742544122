import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { AuthService, TrackDownloadsService } from '../../client';
import { useQuery } from 'react-query';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { useTrackSubmission } from '../../context/TrackSubmissionContext';
import { useAuth } from '../../context/AuthProvider';

export default function ArtistSubmissionButton() {
  const { artistId } = useParams();
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const { toggleSubmissionPanel } = useTrackSubmission();

  const { data: neverDownloaded, isLoading } = useQuery(
    `never-downloaded-artist-${artistId}`,
    () =>
      TrackDownloadsService.getNeverDownloadedFromArtistApiTrackDownloadsNeverDownloadedArtistGet(
        Number(artistId) || -1
      ),
    {
      enabled: isAuthenticated,
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const { data: profile } = useQuery('profile', AuthService.profileApiAuthProfileGet, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  });

  return !isLoading ? (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          mt: 2,
          gap: 2
        }}
      >
        <Button
          variant="contained"
          sx={{ width: '100%', fontSize: 24 }}
          disabled={
            neverDownloaded ||
            !isAuthenticated ||
            !profile?.permissions?.includes('access_premium_artists')
          }
          onClick={toggleSubmissionPanel}
        >
          Submit your Track
        </Button>
      </Box>
      {neverDownloaded && (
        <Typography variant="subtitle1" color="#ffffff6f" sx={{ textAlign: 'center', mt: 1 }}>
          You never downloaded anything from this artist
        </Typography>
      )}
      {!isAuthenticated ? (
        <Typography variant="subtitle1" color="#ffffff6f" sx={{ textAlign: 'center', mt: 1 }}>
          You need to{' '}
          <Link
            to={`/login?next=${location.pathname}`}
            style={{ color: '#fff', textDecoration: 'none' }}
          >
            sign-in
          </Link>{' '}
          to submit a track
        </Typography>
      ) : (
        !profile?.permissions?.includes('access_premium_artists') && (
          <Typography variant="subtitle1" color="#ffffff6f" sx={{ textAlign: 'center', mt: 1 }}>
            You need to have an{' '}
            <NavLink to="/pricing" style={{ color: '#fff', textDecoration: 'none' }}>
              advanced account
            </NavLink>{' '}
            to submit a track
          </Typography>
        )
      )}
    </Box>
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress sx={{ my: 4 }} />
    </Box>
  );
}
