import { useQuery } from 'react-query';
import { PaymentsService } from '../client';

export const useCurrentSubscription = () => {
  const result = useQuery(
    'currentSubscription',
    PaymentsService.currentSubscriptionApiPaymentsCurrentSubscriptionGet,
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: false
    }
  );
  return result;
};
