import { MusicUploadProvider } from '../../../context/MusicUploadContext';
import UploadWizard from '../../../components/MusicUploadWizard/UploadWizard';

export default function ProfileMusicUpload() {
  return (
    <MusicUploadProvider>
      <UploadWizard />
    </MusicUploadProvider>
  );
}
