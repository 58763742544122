import { useMutation } from 'react-query';
import { PaymentsService } from '../client';
import { useSnackBarContext } from '../context/SnackBarContext';

export const useUnsubscribeFeedback = () => {
  const { createSnackBar } = useSnackBarContext();
  return useMutation(PaymentsService.unsubscribeFeedbackApiPaymentsUnsubscribeFeedbackPost, {
    onSuccess: () => {
      createSnackBar({
        content: 'Feedback submitted!',
        autoHide: true,
        severity: 'success'
      });
    },
    onError: (error: Error) => {
      console.error('Feedback error', error);
    }
  });
};
