import saveAs from 'file-saver';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { Box, IconButton, TablePagination, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { TrackListResponse, TracksService } from '../client';
import { AudioWavePlayer } from './AudioWavePlayer';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { Loader } from './Loader';
import { useConfirm } from 'material-ui-confirm';
import { useAudioWavePlayer } from '../context/AudioWavePlayerContext';

const ROWS_PER_PAGE = 5;

export default function ProfileUploadsList() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const queryClient = useQueryClient();
  const { isPlaying: isCurrentPlaying, setIsPlaying: setIsCurrentPlaying } = useAudioWavePlayer();

  const confirm = useConfirm();

  const { data: tracks, isLoading } = useQuery(
    ['tracks', page, rowsPerPage],
    () => TracksService.getTracksApiTracksGet(page, rowsPerPage),
    {
      keepPreviousData: true
    }
  );
  if (isLoading) return <Loader />;

  if (!tracks || tracks.items.length === 0) return <div>No tracks</div>;

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page + 1);
  };

  const handleOnRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleTrackRemove = (track: TrackListResponse) => {
    confirm({
      description: `Are you sure you want to remove ${track.name}?`,
      title: 'Remove track',
      confirmationText: 'Yes',
      cancellationText: 'No'
    })
      .then(() => {
        TracksService.deleteTrackApiTracksTrackIdDelete(track.id).then(() => {
          queryClient.invalidateQueries('tracks');
        });
      })
      .catch(() => {
        console.log('cancelled');
      });
  };

  const handlePlayPause = (track_id: number) => {
    if (isCurrentPlaying !== track_id) {
      setIsCurrentPlaying(track_id);
    }
  };

  return (
    <TableContainer sx={{ mb: 4 }} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">AUDIO</TableCell>
            <TableCell align="left">BPM</TableCell>
            <TableCell align="left">KEY</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tracks.items.map((track) => (
            <TableRow
              key={track.id}
              hover
              role="checkbox"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">
                <AudioWavePlayer
                  src={track.preview_url || ''}
                  track_id={track.id}
                  track_name={track.name}
                  track_image={track.image_url}
                  handlePlayPause={handlePlayPause}
                />
              </TableCell>
              <TableCell align="left">{track.bpm}</TableCell>
              <TableCell align="left">{track.scale}</TableCell>
              <TableCell align="right">
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Tooltip title="Download">
                    <IconButton
                      aria-label="download"
                      onClick={() =>
                        saveAs(track?.data_url as any, `${track.user?.profile_name}-${track.name}`)
                      }
                    >
                      <DownloadIcon sx={{ width: 18, height: 18 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit track">
                    <Link to={`/profile/uploads/${track.id}/edit`}>
                      <IconButton aria-label="edit">
                        <EditIcon sx={{ width: 18, height: 18 }} />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Delete track">
                    <IconButton aria-label="delete" onClick={() => handleTrackRemove(track)}>
                      <DeleteIcon sx={{ width: 18, height: 18 }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tracks.total || 0}
        rowsPerPage={rowsPerPage}
        page={(tracks.page || 1) - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleOnRowsPerPageChange}
      />
    </TableContainer>
  );
}
