import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { useUnsubscribeFeedback } from '../../hooks/useUnsubscribeFeedback';

interface CancelDialogProps {
  open: boolean;
  handleClose: () => void;
  cancelSubscriptionMutation: any;
}
export const CancelDialog = ({
  open,
  handleClose,
  cancelSubscriptionMutation,
}: CancelDialogProps) => {
  const [feedbackData, setFeedbackData] = useState({
    feedback: ''
  });

  const feedbackMutation = useUnsubscribeFeedback();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    setFeedbackData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async () => {
    await feedbackMutation.mutateAsync(feedbackData.feedback);
    await cancelSubscriptionMutation.mutateAsync();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="unsubscribe-dialog-title"
      aria-describedby="unsubscribe-dialog-description"
    >
      <DialogTitle id="unsubscribe-dialog-title">
        <Typography variant="h6">Unsubscribe from Paid Plan</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          We're sorry to see you go! Please help us improve by providing your feedback.
        </Typography>
        <TextField
          name="feedback"
          label="What can we do to improve?"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          margin="normal"
          value={feedbackData.feedback}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
