import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography, Box } from '@mui/material';

interface PaymentSuccessDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const PaymentSuccessDialog = ({ open, handleClose }: PaymentSuccessDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="payment-success-dialog-title"
      aria-describedby="payment-success-dialog-description"
    >
      <DialogTitle id="payment-success-dialog-title">
        <Box display="flex" alignItems="center">
          <CheckCircleIcon color="success" style={{ marginRight: '10px' }} />
          <Typography variant="h6">Payment Successful</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Typography id="payment-success-dialog-description" variant="body1">
          Congratulations! Your account is upgraded successfully.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
