import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { profileSchema } from "../components/Profile/ProfileSchema"

export const useProfileForm = () => {
    const formMethods = useForm({
        resolver: yupResolver(profileSchema),
        mode: 'onChange'
    })
    return formMethods
}