import { useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Card,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

import {
  ApiError,
  SearchResult,
  TrackDownloadResponse,
  TrackDownloadsService,
  TrackListResponse
} from '../../client';
import { TrackRowPlayer } from '../TrackRowPlayer';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { useAuth } from '../../context/AuthProvider';
import { removeExtension } from '../../utils';
import { useAudioWavePlayer } from '../../context/AudioWavePlayerContext';
import { useProfile } from '../../hooks/useProfile';
import SocialPopup from '../SocialPopup';
import slugify from 'react-slugify';

interface TrackRowProps {
  track: TrackListResponse | SearchResult | undefined;
}
export default function TrackRow({ track }: TrackRowProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isAuthenticated } = useAuth();
  const queryClient = useQueryClient();

  const { isPlaying: isCurrentPlaying, setIsPlaying: setIsCurrentPlaying } = useAudioWavePlayer();
  const { data: profile } = useProfile();

  const { createSnackBar } = useSnackBarContext();

  const downloadMutation = useMutation<TrackDownloadResponse, ApiError, number>(
    (requestBody) => TrackDownloadsService.createDownloadTrackApiTrackDownloadsPost(requestBody),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(`never-downloaded-track-${track?.id}`);
        createSnackBar({ content: 'Added to Downloads', autoHide: true, severity: 'success' });
      },
      onError: async (error: ApiError) => {
        if (error.status < 500) {
          createSnackBar({
            content: error.body.detail,
            autoHide: true,
            severity: 'info'
          });
        } else {
          createSnackBar({
            content: 'An error occurred. Please try again later.',
            autoHide: true,
            severity: 'error'
          });
        }
      }
    }
  );
  if (!track) return null;

  const trackNameWithoutExt = removeExtension(track.name);

  const handlePlayPause = (track_id: number) => {
    if (isCurrentPlaying !== track_id) {
      setIsCurrentPlaying(track_id);
    }
  };

  const hasAccessToPremiumArtists =
    isAuthenticated && profile?.permissions?.includes('access_premium_artists');

  const isPremiumContent = (track: TrackListResponse) => track?.user?.account_type === 3;

  const handleDownload = (track: TrackListResponse) => {
    if (isAuthenticated && !isPremiumContent(track)) {
      downloadMutation.mutate(track?.id);
    } else if (hasAccessToPremiumArtists && isPremiumContent(track)) {
      downloadMutation.mutate(track?.id);
    } else if (isAuthenticated && isPremiumContent(track)) {
      createSnackBar({
        content: 'Available for advanced users only. Upgrade now!',
        autoHide: true,
        severity: 'info'
      });
    } else {
      createSnackBar({
        content: 'Please sign-in to download',
        autoHide: true,
        severity: 'info'
      });
    }
  };

  return (
    <Card
      sx={{
        px: { xs: 1, sm: 1, md: 2 },
        py: { xs: 1, sm: 1, md: 2 },
        width: '100%',
        '&:hover': {
          backgroundColor: '#ffffff2f'
        }
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        alignContent="space-between"
        justifyContent="space-between"
      >
        <Grid item xs={9} sm={12} lg={8}>
          <TrackRowPlayer
            src={track.preview_url || ''}
            track_id={track.id}
            track_name={trackNameWithoutExt}
            track_artist={track?.user?.profile_name}
            track_artist_id={track?.user?.id}
            track_image={track.image_url}
            handlePlayPause={handlePlayPause}
            size="small"
          />
        </Grid>
        {!isMobile && (
          <Grid item xs={6} sm={6} lg={2}>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography color="text.secondary" component="div" sx={{ fontSize: '0.9rem' }}>
                  Genre: {track.genres?.map((genre) => genre.name).join(', ')}
                </Typography>
                <Typography color="text.secondary" component="div" sx={{ fontSize: '0.9rem' }}>
                  BPM: {track.bpm}
                </Typography>
                <Typography color="text.secondary" component="div" sx={{ fontSize: '0.9rem' }}>
                  Key: {track.scale}
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={3} sm={3} lg={2} alignItems="end">
          <Box display="flex" justifyContent="end">
            <Tooltip title="Add to My Downloads">
              <IconButton aria-label="download" onClick={() => handleDownload(track)}>
                <DownloadIcon sx={{ fontSize: { xs: '0.9rem', sm: '1.4rem' } }} />
              </IconButton>
            </Tooltip>
            <SocialPopup
              text={trackNameWithoutExt}
              url={`https://muza.studio/track/${track.id}/${slugify(trackNameWithoutExt)}`}
            />
            <Tooltip title="Add to favorites">
              <IconButton aria-label="favorite">
                <FavoriteBorderOutlinedIcon sx={{ fontSize: { xs: '0.9rem', sm: '1.4rem' } }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
