import React, { createContext, useState, useContext } from 'react';
import { TrackDownloadResponse, TrackDownloadsService } from '../client';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from './AuthProvider';

interface TrackSubmissionContextProps {
  isSubmissionPanelOpen: boolean;
  toggleSubmissionPanel: () => void;
  downloadedTracks: TrackDownloadResponse[] | undefined;
}

export const TrackSubmissionContext = createContext<TrackSubmissionContextProps>({
  isSubmissionPanelOpen: false,
  toggleSubmissionPanel: () => {},
  downloadedTracks: []
});

export const useTrackSubmission = () => {
  const { isSubmissionPanelOpen, toggleSubmissionPanel, downloadedTracks } = useContext(TrackSubmissionContext);
  return { isSubmissionPanelOpen, toggleSubmissionPanel, downloadedTracks };
};

export const TrackSubmissionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSubmissionPanelOpen, setIsSubmissionPanelOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const { artistId } = useParams();
  const { data: downloadedTracks } = useQuery(
    ['track-downloads'],
    () =>
      TrackDownloadsService.getDownloadsFromArtistApiTrackDownloadsArtistArtistIdGet(
        Number(artistId) || -1
      ),
    {
      enabled: isAuthenticated,
      keepPreviousData: true
    }
  );

  const toggleSubmissionPanel = () => {
    setIsSubmissionPanelOpen(!isSubmissionPanelOpen);
  };

  return (
    <TrackSubmissionContext.Provider
      value={{
        isSubmissionPanelOpen,
        toggleSubmissionPanel,
        downloadedTracks
      }}
    >
      {children}
    </TrackSubmissionContext.Provider>
  );
};
