import { useState } from 'react';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import { TrackListResponse } from '../client';
import { usePlaylist } from '../hooks/usePlaylist';
import TrackBlock from './TrackBlock';

const swiperStyles = {
  width: '100%',
  height: '100%',
  '--swiper-navigation-color': '#fff',
  '--swiper-navigation-top-offset': '40%',
  '--swiper-navigation-size': '32px',
  '--swiper-navigation-sides-offset': '10px'
};

const swiperBreakpoints = {
  240: {
    slidesPerView: 1,
    spaceBetween: 20
  },
  480: {
    slidesPerView: 2,
    spaceBetween: 20
  },
  640: {
    slidesPerView: 3,
    spaceBetween: 10
  },
  768: {
    slidesPerView: 4,
    spaceBetween: 10
  },
  1024: {
    slidesPerView: 5,
    spaceBetween: 10
  },
  1280: {
    slidesPerView: 6,
    spaceBetween: 10
  },
  1440: {
    slidesPerView: 7,
    spaceBetween: 10
  }
};

interface TracksCarouselProps {
  tracks: TrackListResponse[];
}

export const TracksCarousel = ({ tracks }: TracksCarouselProps) => {
  const [hoveredSlide, setHoveredSlide] = useState<number | null>(null);

  const {
    setPlaylist,
    onPlayPause,
    setCurrentTrack,
    currentTrack,
    playlist,
    convertToPlaylistItem
  } = usePlaylist();

  const handleSlideMouseOver = (trackId: number) => {
    setHoveredSlide(trackId);
  };

  const handleSlideMouseOut = () => {
    setHoveredSlide(null);
  };

  const handlePlayPause = async (selectedTrack: TrackListResponse) => {
    let playListItem = playlist.find((track) => track.id === selectedTrack.id);

    if (selectedTrack.id !== currentTrack?.id) {
      const newPlayList = tracks.map((track) => convertToPlaylistItem(track)) || [];
      playListItem = newPlayList.find((track) => track.id === selectedTrack.id) || newPlayList[0];

      setPlaylist(newPlayList);
      setCurrentTrack(playListItem);
    } else {
      setCurrentTrack(currentTrack);
    }
    setTimeout(() => {
      if (playListItem) {
        onPlayPause(playListItem);
      }
    }, 300);
  };

  return (
    <Box display="flex" width="100%">
      <Swiper
        style={swiperStyles}
        direction={'horizontal'}
        centeredSlides={false}
        navigation={true}
        loop={true}
        modules={[Autoplay, Pagination, Navigation]}
        autoplay={{ delay: 600000, pauseOnMouseEnter: true }}
        slidesPerView={7}
        // freeMode={true}
        breakpoints={swiperBreakpoints}
      >
        {tracks?.map((track, key) => (
          <SwiperSlide
            key={key}
            onMouseOver={() => handleSlideMouseOver(track.id || 0)}
            onMouseOut={handleSlideMouseOut}
          >
            <TrackBlock
              track={track}
              hovered={hoveredSlide || 0}
              onPlayPause={() => handlePlayPause(track)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
