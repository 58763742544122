import { generateShareLink } from '../utils/index';
import { IconButton, Popover, Stack, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from 'react';
import { useSnackBarContext } from '../context/SnackBarContext';

interface ShareLink {
  title: string;
  icon: React.ElementType;
  color: string;
  link: string;
}

export default function SocialPopup({ text, url }: { text: string; url: string }) {
  const { createSnackBar } = useSnackBarContext();
  const shareLinks: ShareLink[] = [
    {
      title: 'Facebook',
      icon: FacebookIcon,
      color: '#fff',
      link: generateShareLink(text, url, 'facebook')
    },
    {
      title: 'Twitter',
      icon: TwitterIcon,
      color: '#fff',
      link: generateShareLink(text, url, 'x')
    },
    {
      title: 'LinkedIn',
      icon: LinkedInIcon,
      color: '#fff',
      link: generateShareLink(text, url, 'linkedin')
    },
    {
      title: 'Email',
      icon: EmailIcon,
      color: '#fff',
      link: generateShareLink(text, url, 'email')
    },
    {
      title: 'WhatsApp',
      icon: WhatsAppIcon,
      color: '#fff',
      link: generateShareLink(text, url, 'whatsapp')
    },
    {
      title: 'Copy Link',
      icon: ContentCopyIcon,
      color: '#fff',
      link: '',
    }
  ];
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const sendToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    createSnackBar({ content: 'Link copied to clipboard', autoHide: true, severity: 'info' });
  };

  return (
    <>
      <Tooltip title="Send">
        <IconButton aria-describedby={id} aria-label="send" onClick={handleClick}>
          <SendIcon sx={{ fontSize: { xs: '0.9rem', sm: '1.4rem' } }} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack direction="row" gap={1} sx={{ p: 2 }}>
          {shareLinks.map((link, index) => (
            <Link key={index} to={
              link.title === 'Copy Link' ? '#' : link.link
            } target={link.title === 'Copy Link' ? '_self' : '_blank'} rel="noreferrer">
              <IconButton
                onClick={() => {
                  if (link.title === 'Copy Link') {
                    sendToClipboard(url);
                  }
                }}
                sx={{
                  color: link.color,
                  backgroundColor: 'transparent',
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                  width: '100%'
                }}
              >
                <link.icon />
              </IconButton>
            </Link>
          ))}

        </Stack>
      </Popover>
    </>
  );
}
