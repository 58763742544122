import { createContext, useState, ReactNode, useEffect, useRef } from 'react';
import { PlaylistItem } from '../components/CentralizedAudio/PlayerWrapper';
import { TrackListResponse } from '../client';

export type PlaylistContextType = {
  playlist: PlaylistItem[];
  currentTrack: PlaylistItem | null;
  setPlaylist: (playlists: PlaylistItem[]) => void;
  setCurrentTrack: (track: PlaylistItem | null) => void;
  convertToPlaylistItem: (track: TrackListResponse) => PlaylistItem;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  audioRef?: React.RefObject<HTMLAudioElement>;
  onPlayPause: (track: PlaylistItem) => Promise<void>;
};

export const PlaylistContext = createContext<PlaylistContextType | undefined>(undefined);

type PlaylistProviderProps = {
  children: ReactNode;
};

export const PlaylistProvider = ({ children }: PlaylistProviderProps) => {
  const [playlist, setPlaylist] = useState<PlaylistItem[]>([]);
  const [currentTrack, setCurrentTrack] = useState<PlaylistItem | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const convertToPlaylistItem = (track: TrackListResponse): PlaylistItem => {
    return {
      name: track.name,
      writer: track.user?.profile_name || '',
      img: track.image_url || '',
      src: `${track.preview_url}?v=${Math.random()}` || '',
      id: track.id,
      genres: track.genres?.map((genre) => genre.name) || []
    };
  };

  const onPlayPause = async (track: PlaylistItem) => {
    const isPlaying =
      (audioRef.current &&
        audioRef.current?.currentTime > 0 &&
        !audioRef.current?.paused &&
        !audioRef.current.ended &&
        audioRef.current.readyState > audioRef.current.HAVE_CURRENT_DATA) ||
      false;

    setCurrentTrack(track);
    try {
      if (!isPlaying) {
        audioRef.current
          ?.play()
          .then(() => {
            console.log('playing...');
          })
          .catch((error) => {
            console.log('error playing audio', error);
          });
      } else {
        audioRef.current?.pause();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handlePlaying = () => {
    setIsPlaying(true);
    // setCurrentTrack(playlist.find((track) => track.src === audioRef?.current?.src) || null);
  };

  const handlePaused = () => {
    setIsPlaying(false);
    // setCurrentTrack(null);
  };

  useEffect(() => {
    audioRef.current?.addEventListener('pause', handlePaused);
    audioRef.current?.addEventListener('play', handlePlaying);

    return () => {
      audioRef.current?.removeEventListener('pause', handlePaused);
      audioRef.current?.removeEventListener('play', handlePlaying);
    };
  }, [audioRef.current, currentTrack, playlist]);

  //   useEffect(() => {
  //     if ('mediaSession' in navigator) {
  //       //   navigator.mediaSession.setActionHandler('play', () => onPlay(playlistItem));
  //       //   navigator.mediaSession.setActionHandler('pause', onPause);
  //       if (currentTrack) {
  //         navigator.mediaSession.metadata = new MediaMetadata({
  //           title: currentTrack.name,
  //           artist: currentTrack.writer,
  //           artwork: [{ src: currentTrack.img }]
  //         });
  //       }
  //     }

  //     return () => {
  //       if ('mediaSession' in navigator) {
  //         navigator.mediaSession.setActionHandler('play', null);
  //         navigator.mediaSession.setActionHandler('pause', null);
  //       }
  //     };
  //   }, [currentTrack, playlist]);

  const value = {
    playlist,
    currentTrack,
    setPlaylist,
    setCurrentTrack,
    convertToPlaylistItem,
    isPlaying,
    setIsPlaying,
    audioRef,
    onPlayPause
  };

  return <PlaylistContext.Provider value={value}>{children}</PlaylistContext.Provider>;
};
