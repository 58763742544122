import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useConfirm } from 'material-ui-confirm';
import { Box, Button, Paper, Typography, TextField, Divider, Alert } from '@mui/material';
import { useTrackSubmission } from '../../context/TrackSubmissionContext';
import UploadProgress from '../UploadProgress';
import ZipDropZone from '../ZipDropZone';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { useParams } from 'react-router-dom';

interface SubmitTrackRequest {
  zip_file: File;
  notes: string;
  track_id: number;
}

export default function TrackSubmissionPanel() {
  const { trackId } = useParams();
  const { isSubmissionPanelOpen } = useTrackSubmission();
  const [zip, setZip] = useState<File | null>(null);
  const [notes, setNotes] = useState<string>('');
  const [errors, setErrors] = useState({ zip: '' });
  const [zipUploadProgress, setZipUploadProgress] = useState<number>(0);
  const [isZipUploaded, setIsZipUploaded] = useState<boolean>(false);
  const [isTrackSubmitted, setIsTrackSubmitted] = useState<boolean>(false);

  const { createSnackBar } = useSnackBarContext();

  const confirm = useConfirm();

  const submitTrackMutation = useMutation(
    (newTrack: SubmitTrackRequest) => {
      return axios.post(`/api/track_downloads/submit_track`, newTrack, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0;
          setZipUploadProgress(percentCompleted);
          console.log(percentCompleted);
        }
      });
    },
    {
      onSuccess: (response) => {
        setIsZipUploaded(true);
        setIsTrackSubmitted(true);
      },
      onError: (error: AxiosError<any>) => {
        const { response } = error;
        if (!response) {
          createSnackBar({
            content: 'An error occurred. Please try again later.',
            autoHide: true,
            severity: 'error'
          });
          return;
        }

        if (response.status < 500) {
          createSnackBar({
            content: error?.response?.data?.detail,
            autoHide: true,
            severity: 'info'
          });
        } else {
          createSnackBar({
            content: 'An error occurred. Please try again later.',
            autoHide: true,
            severity: 'error'
          });
        }
        setIsZipUploaded(false);
        setErrors({ ...errors, zip: 'Error uploading zip file' });
      }
    }
  );

  const handleFileUpload = (acceptedFiles: File[]) => {
    setErrors({ ...errors, zip: '' });
    setZip(acceptedFiles[0]);
  };

  const onZipUploadCancel = () => {
    confirm({
      description: 'Are you sure you want to cancel uploading this zip file?',
      title: 'Cancel Upload',
      confirmationText: 'Yes',
      cancellationText: 'No'
    })
      .then(() => {
        setZip(null);
      })
      .catch(() => {
        console.log('cancelled');
      });
  };

  if (isTrackSubmitted)
    return (
      <Paper sx={{ mt: 2 }}>
        <Box sx={{ p: 3, width: '100%' }}>
          <Typography sx={{ textAlign: 'center', my: 8 }} variant="h5" gutterBottom>
            Your track has been submitted successfully!
          </Typography>
        </Box>
      </Paper>
    );

  return isSubmissionPanelOpen ? (
    <Paper sx={{ mt: 2 }}>
      <Box sx={{ p: 3, width: '100%' }}>
        <Typography sx={{ textAlign: 'center' }} variant="h5" gutterBottom>
          Submit a track
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', mt: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" color="#ffffff7f" sx={{ mt: 1, mb: 2 }} gutterBottom>
              Please provide notes about the track you are submitting.
            </Typography>
            <TextField
              sx={{ width: '100%' }}
              onChange={(e) => setNotes(e.target.value)}
              label="Notes about the track"
              minRows={8}
              multiline
            />
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 4 }} />
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 2 }} color="#ffffff7f">
              The zip file should contain all the stems of your track.
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Project files
            </Typography>
            <ZipDropZone onFileUpload={handleFileUpload} />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              {zip && (
                <UploadProgress
                  fileName={zip.name}
                  fileSize={zip.size}
                  progress={zipUploadProgress}
                  onCancel={onZipUploadCancel}
                  uploaded={zipUploadProgress === 100 && isZipUploaded}
                  uploadAfter
                />
              )}
            </Box>
            {errors.zip && (
              <Alert sx={{ my: 1 }} variant="outlined" severity="error">
                {errors.zip}
              </Alert>
            )}
          </Box>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{ fontSize: 18, mt: 4, mx: 'auto' }}
            variant="contained"
            color="primary"
            onClick={() => {
              if (!trackId) return;
              if (!zip) return;
              submitTrackMutation.mutate({
                zip_file: zip,
                notes: notes,
                track_id: Number(trackId)
              });
            }}
            disabled={submitTrackMutation.isLoading || !zip || !trackId}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Paper>
  ) : null;
}
