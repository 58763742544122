import { Box, TextField, Typography } from '@mui/material';
import { GenrePicker } from '../GenrePicker';
import { useMusicUpload } from '../../context/MusicUploadContext';
import { Track, TracksService } from '../../client';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';

export default function UpdateTrackInformation() {
  const { track, setTrack, formMethods } = useMusicUpload();

  const { control } = formMethods;

  useQuery(
    `track-${track?.id}`,
    () => TracksService.getTrackDetailsWizardApiTracksTrackIdWizardGet(Number(track?.id) || -1),
    {
      enabled: !!track?.id,
      onSuccess: (data: Track) => {
        setTrack(data);
      }
    }
  );

  if (!track) return null;

  return (
    <Box>
      <Typography variant="h6" color="#ffffffee" gutterBottom>
        Update Track Information
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4 }} color="#ffffff7f">
        Provide the name and description which would best describe your track.
      </Typography>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field: { value, onChange } }) => (
          <TextField
            fullWidth
            label="Track Name"
            margin="none"
            required
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="about"
        control={formMethods.control}
        defaultValue=""
        render={({ field: { value, onChange } }) => (
          <TextField
            fullWidth
            label="Description"
            margin="normal"
            multiline
            rows={4}
            value={value}
            onChange={onChange}
            helperText="Please describe your goals for this collaboration. Share any specific notes or ideas you want your collaborator to know. This could include the mood or style you're aiming for, any particular instruments or techniques you want to incorporate, or other creative visions you have in mind."
          />
        )}
      />
      <Controller
        name="genres"
        control={control}
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <GenrePicker onChange={onChange} selectedGenres={value || []} />
        )}
      />
    </Box>
  );
}
