import { Box, Container, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';
import BreadcrumbsPanel from '../../components/BreadcrumbsPanel';
import SubmissionsSentList from '../../components/Submissions/SubmissionsSentList';

export default function ProfileSubmissionsSent() {
  const location = useLocation();
  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <BreadcrumbsPanel breadcrumbs={[{ label: "Submissions Sent", href: location.pathname }]} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Submissions Sent
        </Typography>
      </Box>
      <SubmissionsSentList />
    </Container>
  );
}
