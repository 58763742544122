import { Box, Container, Typography } from '@mui/material';
import SignUpExploreButton from './Home/SignUpExploreButton';

export default function CallToActionSection() {
  return (
    <Box
      sx={{
        minHeight: 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingY: 10,
        gap: 5,
        justifyContent: 'center',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          backgroundImage: 'url(/assets/start-today.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          filter: 'brightness(0.3)'
        }}
      />
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          height: '100%',
          opacity: 0.3,
          background: 'linear-gradient(90deg, #410d26, #74057a, #5d002c, #240426)'
        }}
      ></Box>
      <Container maxWidth="sm" sx={{ zIndex: 1 }}>
        <Typography
          variant="h4"
          sx={{
            textAlign: 'center'
          }}
          gutterBottom
        >
          <Typography variant="h4" color="primary">Start Collaborating</Typography>
          Today!
        </Typography>
      </Container>
      <SignUpExploreButton />
    </Box>
  );
}
