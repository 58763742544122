import { Box, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import FeaturedTracks from '../components/FeaturedTracks';
import FeaturedArtists from '../components/FeaturedArtists';
import MostRecentTracks from '../components/MostRecentTracks';
import MostDownloadedTracks from '../components/MostDownloadedTracks';
import Hero from '../components/Hero';
import BenefitsSection from '../components/Home/BenefitsSection';
import WhatIndustryThinkSection from '../components/Home/WhatIndustryThinkSection';
import CallToActionSection from '../components/CallToActionSection';
import { CentralizedAudio } from '../components/CentralizedAudio';
import MuzaLogo from '../components/MuzaLogo';
import InfoSection from '../components/Home/InfoSection';
import { useVerifyEmail } from '../hooks/useVerifyEmail';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackBarContext } from '../context/SnackBarContext';
import { useEffect } from 'react';
import UpcomingProjects from '../components/UpcomingProjects';
// https://500px.com/photo/1069766029/martin-garrix-by-niclas-glasberg
// https://codepen.io/ak545/pen/OeMWOJ
// https://codepen.io/aqandrew/pen/OJVNRgV

export default function Home() {
  // const [searchParams] = useSearchParams();
  // const navigate = useNavigate();
  // const { createSnackBar } = useSnackBarContext();

  // useEffect(() => {
  //   if (searchParams.get('payment-success')) {
  //     createSnackBar({
  //       content: 'Payment successful!',
  //       autoHide: true,
  //       severity: 'success'
  //     });
  //     navigate('/')
  //   }
  // }, [searchParams]);
  
  useVerifyEmail();

  return (
    <>
      <Box
        sx={{
          // backgroundImage: `url(/stage-dark.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'bottom center',
          color: 'white'
        }}
      >
        <Hero />
      </Box>
      <Container maxWidth="lg" id="explore" sx={{ py: 4}}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <Typography variant="h5" sx={{ my: 4 }}>
              Featured Artists
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <FeaturedTracks />
              </Grid>
              <Grid item xs={12} md={4}>
                <FeaturedArtists />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="h5" sx={{ my: 4 }}>
          Top Downloaded
        </Typography>
        <MostDownloadedTracks />
        <Typography variant="h5" sx={{ my: 4 }}>
          Latest Projects
        </Typography>
        <MostRecentTracks />
        <Typography variant="h5" sx={{ my: 4 }}>
          Upcoming Projects
        </Typography>
        <UpcomingProjects />
      </Container>
      <InfoSection />
      <Container maxWidth="lg" id="benefits">
        <BenefitsSection />
        <Divider sx={{ mt: 10 }} />
        <Typography
          variant="h4"
          sx={{
            mt: 4,
            mb: 8,
            textAlign: 'center',
            verticalAlign: 'middle'
          }}
        >
          What industry leaders think
        </Typography>
        <WhatIndustryThinkSection />
      </Container>
      <CallToActionSection />
      <CentralizedAudio />
    </>
  );
}
