import { useMutation } from 'react-query';
import { AuthService } from '../client';
import { useSnackBarContext } from '../context/SnackBarContext';

export const useHideProfile = () => {
  const { createSnackBar } = useSnackBarContext();
  return useMutation(AuthService.hideProfileApiAuthHideProfilePost, {
    onSuccess: () => {
      createSnackBar({
        content: 'Profile visibility has been updated successfully',
        autoHide: true,
        severity: 'success'
      });
    },
    onError: () => {
      createSnackBar({
        content: 'Profile visibility update failed',
        autoHide: true,
        severity: 'error'
      });
    }
  });
};
