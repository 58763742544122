import { useQuery } from 'react-query';

import 'swiper/css';
import 'swiper/css/pagination';

import { Loader } from '../Loader';
import { TracksService } from '../../client';
import { UpcomingCarousel } from './UpcomingCarousel';

export default function UpcomingProjects() {
  const { data: upcomingTracks, isLoading } = useQuery(
    'upcoming-page-public',
    TracksService.getUpcomingTracksApiTracksUpcomingProjectsGet,
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!upcomingTracks) {
    return null;
  }

  return <UpcomingCarousel tracks={upcomingTracks} />;
}
