import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Track, TrackGenre, TrackInfoUpdateRequest, TracksService } from '../client';
import {
  imageUploadSchema,
  upcomingTrackSchema
} from '../components/MusicUploadWizard/UploadSchema';

interface MusicUploadContextProps {
  activeStep: number;
  setActiveStep: (step: number) => void;
  track: Track | null;
  setTrack: (track: Track | null) => void;
  handleNext: () => void;
  handleBack: () => void;
  apiErrors: any;
  setApiErrors: (errors: any) => void;
  formMethods: UseFormReturn<FieldValues, any, FieldValues>;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

export const UpcomingProjectContext = createContext<MusicUploadContextProps | undefined>(undefined);

export function useAddUpcomingProject() {
  const context = useContext(UpcomingProjectContext);
  if (!context) {
    throw new Error('useAddUpcomingProject must be used within a MusicUploadProvider');
  }
  return context;
}

export function UpcomingProjectProvider({ children }: { children: React.ReactNode }) {
  const [activeStep, setActiveStep] = useState(0);
  const [track, setTrack] = useState<any>(null);
  const [apiErrors, setApiErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const currentSchema = useMemo(() => {
    switch (activeStep) {
      case 0:
        return upcomingTrackSchema;
      case 1:
        return imageUploadSchema;
      default:
        return yup.object().shape({});
    }
  }, [activeStep]);

  useEffect(() => {
    async function trigger() {
      await formMethods.trigger();
    }
    trigger();
  }, [currentSchema]);

  const formMethods = useForm({
    resolver: yupResolver(currentSchema),
    mode: 'onChange'
  });

  const watchFormData = formMethods.watch() as TrackInfoUpdateRequest;

  useEffect(() => {
    if (track) {
      console.log('resetting form:', track);
      formMethods.reset({
        ...formMethods.getValues(),
        ...track,
      });
    }
  }, [track, formMethods]);

  const createUpcomingProjectMutation = useMutation<Track, Error, TrackInfoUpdateRequest>(
    (requestBody) => TracksService.upcomingProjectApiTracksUpcomingProjectPost(requestBody),
    {
      onSuccess: (data) => {
        console.log('success');
        setTrack(data);
      },
      onError: async (error: any) => {
        console.log(error);
      }
    }
  );

  const handleNext = async () => {
    switch (activeStep) {
      case 0:
        await createUpcomingProjectMutation.mutateAsync({
          name: watchFormData.name,
        });

        break;
      default:
        break;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <UpcomingProjectContext.Provider
      value={{
        activeStep,
        setActiveStep,
        track,
        setTrack,
        handleNext,
        handleBack,
        apiErrors,
        setApiErrors,
        formMethods,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </UpcomingProjectContext.Provider>
  );
}
