import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { TrackListResponse } from '../../client';
import { Avatar, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { usePlaylist } from '../../hooks/usePlaylist';
import { generateCldUrl, removeExtension } from '../../utils';
import slugify from 'react-slugify';

interface FeaturedTrackProps {
  track: TrackListResponse;
  active: boolean;
  onPlayPause: () => void;
}
export default function FeaturedTrack({ track, active, onPlayPause }: FeaturedTrackProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { isPlaying, currentTrack } = usePlaylist();

  const handlePlayPause = () => {
    onPlayPause();
  };

  const trackNameWithoutExt = removeExtension(track.name);

  const cld_track_image = track.user?.avatar_url && generateCldUrl(track.user?.avatar_url || "", "featured_track");

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        position: 'relative',
        borderRadius: 0
      }}
    >
      <Link
        to={`/artist/${track.user?.id}/${slugify(track.user?.profile_name)}`}
        style={{ width: '100%', height: '100%' }}
      >
        <Avatar
          sx={{
            width: '100%',
            height: '100%',
            transition: 'filter 0.6s ease',
            filter: active || isPlaying || isMobile ? 'brightness(1)' : 'brightness(0.8)',
            aspectRatio: '1/1'
          }}
          variant="square"
          src={cld_track_image}
          alt={track.name}
        />
      </Link>

      <Paper
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          background: '#0000003f',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          borderRadius: 0,
          height: active || isPlaying || isMobile ? 100 : 0,
          transition: 'height 0.5s ease',
          gapX: 2,
          px: active || isPlaying || isMobile ? 2 : 0,
          py: active || isPlaying || isMobile ? 2 : 0,
          zIndex: 2
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
          sx={{ display: active || isPlaying || isMobile ? 'inherit' : 'none' }}
        >
          <IconButton
            aria-label={track.id === currentTrack?.id && isPlaying ? 'pause' : 'play'}
            onClick={handlePlayPause}
          >
            {track.id === currentTrack?.id && isPlaying ? (
              <PauseIcon sx={{ fontSize: { xs: '1.4rem', sm: '2rem' } }} />
            ) : (
              <PlayArrowIcon sx={{ fontSize: { xs: '1.4rem', sm: '2rem' } }} />
            )}
          </IconButton>
          <Box display="flex" flexDirection="column" sx={{ my: 1 }}>
            <Link
              to={`/track/${track.id}/${slugify(trackNameWithoutExt)}`}
              style={{
                textDecoration: 'none',
                display: active || isPlaying || isMobile ? 'inherit' : 'none'
              }}
            >
              <Typography
                color="#ffffffee"
                noWrap={isMobile}
                sx={{
                  maxWidth: { xs: 180, sm: 400, md: 500 },
                  fontSize: { xs: '1rem', sm: '1.2rem' }
                }}
              >
                {trackNameWithoutExt}
              </Typography>
            </Link>
            <Link
              to={`/artist/${track.user?.id}/${slugify(track.user?.profile_name)}`}
              style={{
                textDecoration: 'none',
                display: active || isPlaying || isMobile ? 'inherit' : 'none'
              }}
            >
              <Typography color="text.secondary" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                {track.user?.profile_name}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
