import React, { createContext, useContext, useState } from 'react';
// import WaveSurfer from 'wavesurfer.js';

interface AudioWavePlayerContextType {
  isPlaying: number;
  setIsPlaying: (isPlaying: number) => void;
}

const AudioWavePlayerContext = createContext<AudioWavePlayerContextType | undefined>(undefined);

export const AudioWavePlayerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState<number>(-1);

  return (
    <AudioWavePlayerContext.Provider value={{ isPlaying, setIsPlaying }}>
      {children}
    </AudioWavePlayerContext.Provider>
  );
};

export const useAudioWavePlayer = (): AudioWavePlayerContextType => {
  const audioContext = useContext(AudioWavePlayerContext);
  if (!audioContext) {
    throw new Error('useAudioWavePlayer must be used within an AudioWavePlayerProvider');
  }
  return audioContext;
};
