import { Box } from '@mui/material';
import PlayerWrapper from './PlayerWrapper';
import { usePlaylist } from '../../hooks/usePlaylist';

export const CentralizedAudio = () => {
  const { playlist, currentTrack, audioRef } = usePlaylist();

  return (
    <Box sx={{ zIndex: 999 }}>
      <PlayerWrapper ref={audioRef} playList={playlist} currentTrack={currentTrack} />
    </Box>
  );
};
