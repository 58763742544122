/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TrackArtist } from '../models/TrackArtist';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ArtistsService {

    /**
     * Get Featured Artists
     * @returns TrackArtist Successful Response
     * @throws ApiError
     */
    public static getFeaturedArtistsApiArtistsFeaturedGet(): CancelablePromise<Array<TrackArtist>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/artists/featured',
        });
    }

}
