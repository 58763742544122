import { Box, Button, Container, Typography } from '@mui/material';
import BreadcrumbsPanel from '../../../components/BreadcrumbsPanel';
import { useLocation } from 'react-router-dom';
import UpcomingProjectsList from '../../../components/UpcomingProjects/UpcomingProjectsList';

export default function ProfileUpcoming() {
  const location = useLocation();
  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <BreadcrumbsPanel breadcrumbs={[{ label: 'Upcoming Projects', href: location.pathname }]} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Upcoming Projects
        </Typography>
        <Button variant="contained" color="primary" href="/profile/upcoming-projects/add">
          Add Upcoming Project
        </Button>
      </Box>
      <UpcomingProjectsList />
    </Container>
  );
}
