import { Box, Container, Typography } from '@mui/material';
import SignUpExploreButton from './Home/SignUpExploreButton';

export default function Hero() {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: { xs: '75vh', sm: '100vh' },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        backgroundSize: 'cover',
        backgroundPosition: '60%',
        mt: -8
      }}
    >
      <Box
        component="video"
        autoPlay
        muted
        loop
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      >
        <source src="https://muza.nyc3.digitaloceanspaces.com/muza-intro.mp4" type="video/mp4" />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          width: '100%',
          height: { xs: '75vh', sm: '100vh' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: { xs: 'space-evenly', sm: 'space-evenly' },
          background: 'linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 105%)'
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 5,
              width: 680,
              maxWidth: '100%'
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: { xs: '3rem', sm: '3rem', lg: '4rem' },
                lineHeight: '4rem',
                textAlign: 'left'
              }}
            >
              <Typography
                variant="h1"
                color="primary"
                sx={{ fontSize: { xs: '3rem', sm: '3rem', lg: '4rem' } }}
              >
                Collaborate with your
              </Typography>
              favourite artists.
            </Typography>
            <Typography sx={{ textShadow: '0px 0px 10px #000000', maxWidth: 500 }}>
              Muza is a collaboration platform that allows musicians to get inspired by famous
              artists, download sketches, and collaborate with other musicians.
            </Typography>
            <SignUpExploreButton />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
