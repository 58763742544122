import * as React from 'react';

export default function MuzaLogo({ color = 'white' }: { color?: string }) {
  return (
    <svg
      version="1.1"
      viewBox="200 0 2000 800"
      width="128"
      height="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(325,155)"
        fill={color}
        d="m0 0h28l20 4 13 5 16 9 13 11 9 10 7 11 8 16 5 19 2 21 1 206 3 11 5 10 10 11 11 7 10 4 5 1h15l11-3 10-5 10-9 7-10 4-11 2-15 1-210 3-16 6-16 9-17 8-10 10-10 14-10 14-7 16-5 13-2h28l20 4 15 6 13 8 10 8 11 11 10 15 7 16 4 14 2 17v298l-5 1h-47l-1-1-1-52v-246l-1-11-4-11-7-12-9-8-16-8-11-2h-9l-14 3-10 5-10 8-7 10-5 11-2 10-1 194-1 25-4 19-6 15-6 11-10 13-9 9-11 8-16 8-15 5-15 3h-26l-19-4-15-6-14-8-10-8-10-10-10-15-8-17-5-21-1-11-1-209-3-14-6-11-9-10-10-7-13-5-8-1h-9l-13 3-12 6-10 9-7 11-4 11-1 9-1 150-3 1h-49l-1-1v-153l3-16 6-17 9-16 8-10 5-6 14-11 14-8 13-5z"
      />
      <path
        transform="translate(1637,153)"
        fill={color}
        d="m0 0h28l18 2 24 6 20 8 14 8 12 9 12 11 10 11 10 15 8 16 7 19 5 25 1 8 1 19v239l-1 1-52 1-1-1v-138l-203 1-1 137h-52l-1-1v-254l3-23 5-19 6-15 10-19 10-14 11-12 10-9 15-10 16-8 16-6 21-5zm4 47-19 3-14 5-12 6-12 9-11 11-9 13-8 17-5 18-2 21v54h203l1-1v-60l-3-21-7-19-8-14-8-10-10-10-13-9-15-7-14-4-14-2z"
      />
      <path
        transform="translate(823,162)"
        fill={color}
        d="m0 0h52l1 1 1 257 4 21 6 15 7 13 8 10 2 4h2v2l4 2 11 9 16 8 15 5 13 2h27l16-3 16-6 14-8 14-12 9-12 7-12 7-19 3-15 1-28v-232l1-2h53v251l-2 21-6 25-6 16-9 17-10 14-11 12-11 10-15 10-16 8-20 7-25 5-9 1h-32l-20-3-19-5-20-8-16-9-10-8-10-9-9-9-10-14-9-16-6-15-5-17-3-16-1-9-1-31v-226z"
      />
      <path
        transform="translate(1191,162)"
        fill={color}
        d="m0 0h209l15 3 14 6 11 8 5 4 10 13 6 13 4 18v18l-3 16-5 12-8 12-12 13-14 12-11 9-32 26-13 11-10 8-17 14-11 9-34 28-11 9-16 13-17 14-11 9-4 7-1 4v11l3 9 7 8 10 5 6 1 203 1 1 1v45l-18 1h-182l-20-1-16-3-14-6-12-9-7-7-7-10-7-15-3-12-1-9v-9l2-14 5-14 7-11 11-13 14-12 11-9 17-14 22-18 13-11 14-11 9-8 22-18 17-14 13-11 10-8 17-14 11-9 10-9 4-7 2-12-3-11-6-8-8-5-16-2h-181l-4-1z"
      />
    </svg>
  );
}
