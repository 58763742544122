/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_complete_submission_upload_api_track_downloads__track_id__complete_multipart_upload_post } from '../models/Body_complete_submission_upload_api_track_downloads__track_id__complete_multipart_upload_post';
import type { Body_initiate_submission_upload_api_track_downloads__track_id__initiate_multipart_upload_post } from '../models/Body_initiate_submission_upload_api_track_downloads__track_id__initiate_multipart_upload_post';
import type { Body_submit_track_api_track_downloads_submit_track_post } from '../models/Body_submit_track_api_track_downloads_submit_track_post';
import type { Body_upload_submission_zip_chunk_api_track_downloads__track_id__upload_zip_chunk_put } from '../models/Body_upload_submission_zip_chunk_api_track_downloads__track_id__upload_zip_chunk_put';
import type { Page_Submission_ } from '../models/Page_Submission_';
import type { Page_TrackDownloadResponse_ } from '../models/Page_TrackDownloadResponse_';
import type { TrackDownloadResponse } from '../models/TrackDownloadResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TrackDownloadsService {

    /**
     * Get Track Downloads
     * @param page
     * @param size
     * @returns Page_TrackDownloadResponse_ Successful Response
     * @throws ApiError
     */
    public static getTrackDownloadsApiTrackDownloadsGet(
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_TrackDownloadResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/track_downloads',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Download Track
     * @param trackId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createDownloadTrackApiTrackDownloadsPost(
        trackId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/track_downloads',
            query: {
                'track_id': trackId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Track Download
     * @param trackId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteTrackDownloadApiTrackDownloadsDelete(
        trackId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/track_downloads',
            query: {
                'track_id': trackId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Track Download
     * @param trackId
     * @returns TrackDownloadResponse Successful Response
     * @throws ApiError
     */
    public static getTrackDownloadApiTrackDownloadsTrackIdGet(
        trackId: number,
    ): CancelablePromise<TrackDownloadResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/track_downloads/{track_id}',
            path: {
                'track_id': trackId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Never Downloaded From Artist
     * @param artistId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static getNeverDownloadedFromArtistApiTrackDownloadsNeverDownloadedArtistGet(
        artistId: number,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/track_downloads/never_downloaded/artist',
            query: {
                'artist_id': artistId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Never Downloaded Track
     * @param trackId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static getNeverDownloadedTrackApiTrackDownloadsNeverDownloadedTrackGet(
        trackId: number,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/track_downloads/never_downloaded/track',
            query: {
                'track_id': trackId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Downloads From Artist
     * @param artistId
     * @returns TrackDownloadResponse Successful Response
     * @throws ApiError
     */
    public static getDownloadsFromArtistApiTrackDownloadsArtistArtistIdGet(
        artistId: number,
    ): CancelablePromise<Array<TrackDownloadResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/track_downloads/artist/{artist_id}',
            path: {
                'artist_id': artistId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Submit Track
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static submitTrackApiTrackDownloadsSubmitTrackPost(
        formData: Body_submit_track_api_track_downloads_submit_track_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/track_downloads/submit_track',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Initiate Submission Upload
     * @param trackId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static initiateSubmissionUploadApiTrackDownloadsTrackIdInitiateMultipartUploadPost(
        trackId: number,
        formData: Body_initiate_submission_upload_api_track_downloads__track_id__initiate_multipart_upload_post,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/track_downloads/{track_id}/initiate_multipart_upload',
            path: {
                'track_id': trackId,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Submission Zip Chunk
     * @param trackId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static uploadSubmissionZipChunkApiTrackDownloadsTrackIdUploadZipChunkPut(
        trackId: number,
        formData: Body_upload_submission_zip_chunk_api_track_downloads__track_id__upload_zip_chunk_put,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/track_downloads/{track_id}/upload_zip_chunk',
            path: {
                'track_id': trackId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Complete Submission Upload
     * @param trackId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static completeSubmissionUploadApiTrackDownloadsTrackIdCompleteMultipartUploadPost(
        trackId: number,
        formData: Body_complete_submission_upload_api_track_downloads__track_id__complete_multipart_upload_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/track_downloads/{track_id}/complete_multipart_upload',
            path: {
                'track_id': trackId,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User Submissions
     * @param page
     * @param size
     * @returns Page_Submission_ Successful Response
     * @throws ApiError
     */
    public static getUserSubmissionsApiTrackDownloadsSubmissionsSentGet(
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_Submission_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/track_downloads/submissions/sent',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Submissions Received
     * @param page
     * @param size
     * @returns Page_Submission_ Successful Response
     * @throws ApiError
     */
    public static getSubmissionsReceivedApiTrackDownloadsSubmissionsReceivedGet(
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_Submission_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/track_downloads/submissions/received',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
