import { Container, Typography, List, ListItem, Paper } from '@mui/material';
import BreadcrumbsPanel from '../components/BreadcrumbsPanel';
import { useLocation } from 'react-router-dom';

export default function TermsOfService() {
  const location = useLocation();
  return (
    <Container maxWidth="md" sx={{ mt: 2, mb: 10 }}>
      <BreadcrumbsPanel breadcrumbs={[{ label: 'Terms of Service', href: location.pathname }]} />

      <Paper sx={{ px: 6, py: 4 }}>
        <Typography variant="h2" gutterBottom>
          Muza Terms Of Use
        </Typography>

        <Typography paragraph>
          Welcome to MUZA, This Terms of Use Agreement (collectively, “Agreement” or “Terms”)
          constitutes a legally binding agreement made between you, whether personally or on behalf
          of an entity (“user” or “you”) and MUZA (collectively, “MUZA” or “Company”, “we”, “us” or
          “our”), concerning your access to and use of the https://www.MUZA.com/ website as well as
          any other media form, media channel, mobile website related or connected thereto
          (collectively, the “Website”). The Website provides the following service: connecting
          between musicians and promoting collaborations between musicians (“Services” or “Company
          Services”). Please read carefully. YOU ACCEPT AND AGREE TO BE BOUND BY THIS AGREEMENT BY
          ACKNOWLEDGING SUCH ACCEPTANCE DURING THE REGISTRATION PROCESS (IF APPLICABLE) AND ALSO BY
          CONTINUING TO USE THE WEBSITE. IF YOU DO NOT AGREE TO ABIDE BY THIS AGREEMENT, DO NOT USE
          OR ACCESS OR CONTINUE TO USE OR ACCESS THE SERVICES OR THE WEBSITE.
        </Typography>

        <Typography variant="h5" gutterBottom>
          General
        </Typography>

        <Typography paragraph>
          The purpose of this agreement is to promote remote musical collaborations and partnership
          between musicians. Musicians from all over the world can upload their own original musical
          creation and by so, allowing other musicians to further evolve the original musical
          creation and to set the terms of how to publish the new collaboration.
        </Typography>

        <List sx={{ listStyleType: 'decimal' }}>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              Eligible use of the site and its services.
            </Typography>
            <Typography paragraph>
              By using the Company Services, you represent and warrant that:
            </Typography>
            <List sx={{ listStyleType: 'upper-Latin' }}>
              <ListItem sx={{ display: 'list-item' }}>
                all registration information you submit is truthful and accurate;
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                you will maintain the accuracy of such information;
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                you will keep your password confidential and will be responsible for all use of your
                password and account;
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                you are not a minor in the jurisdiction in which you reside, or if a minor, you have
                received parental permission to use this Website; and
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                your use of the Company Services does not violate any applicable law or regulation.
              </ListItem>
            </List>
            <Typography paragraph>
              You also agree to (a) provide true, accurate, current and complete information about
              yourself as prompted by the Website’s registration form and (b) maintain and promptly
              update registration data to keep it true, accurate, current and complete. If you
              provide any information that is untrue, inaccurate, not current or incomplete, or
              Company has reasonable grounds to suspect that such information is untrue, inaccurate,
              not current or incomplete, Company has the right to suspend or terminate your account
              and refuse any and all current or future use of the Website (or any portion thereof).
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              Opening an account, registering and uploading.
            </Typography>
            <List>
              <ListItem>
                2.1. You do not need to register to search for music or download a preview file. But
                in order to upload or download music for the purpose of this site, you need to
                register and create an account.
              </ListItem>
              <ListItem>
                2.2. To create an account, you will be required to provide information including
                your full name, username, email address, and password that you have chosen. You are
                solely responsible for the activities in your account and agree to provide accurate
                information when registering for the Website and to maintain accurate and up-to-date
                information at all times. Protect your username and password at all times.
              </ListItem>
              <ListItem>
                2.3. Submission of incorrect, inaccurate, false, or misleading information
                constitutes a complete breach of these Terms. In such a case, we reserve the right
                to suspend or terminate your activity or access to your account and refuse any full
                or partial use of the Site and Services.
              </ListItem>
              <ListItem>
                2.4 by uploading music to MUZA site you warrant that all of the material and music
                you upload is original and you own all of the copyrights in the music and the
                recordings you upload.
              </ListItem>
              <ListItem>
                2.5 you are the exclusive owner of the music and legally authorized and legally
                capable to enter into this Agreement.
              </ListItem>
              <ListItem>
                2.6 the Music you upload to the site is original and does not infringe any
                third-party rights, privacy and performance rights including intellectual property.
              </ListItem>
              <ListItem>
                2.7 the Music does not violate the law in any way including applicable copyright law
                and defamation law;
              </ListItem>
              <ListItem>
                2.8 you will not upload or otherwise make available on the Site any Music, material
                or other content which is illegal, harmful, threatening, abusive, harassing,
                tortious, defamatory, vulgar, obscene, libelous or that contains any virus, trap
                door, trojan horse or in any way attempts to destroy, disable, control or impede the
                functionality or operation of the Site or its host server;
              </ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              Collaboration and credit
            </Typography>
            <List>
              <ListItem>
                3.1. All collaborations through MUZA will be ruled by the mandatory terms of this
                agreement with regards to credit and profit and registration.{' '}
              </ListItem>
              <ListItem>
                3.2. When you download or upload music, you must provide complete and accurate
                information, both for the license to use or allow the music for the purpose of
                collaboration.{' '}
              </ListItem>
              <ListItem>
                3.3 when you upload or download a music for the purpose of collaboration with other
                musicians you agree that the musician who will collaborate with you will be entitled
                to a credit which will be agreed upon by the parties.{' '}
              </ListItem>
              <ListItem>
                3.4 When you download music you will be restricted to this agreement terms and to
                the uses it allows you of the music. Any use that is not specified in the agreement
                might result in a copyright infringement. You will have the right to privately use
                the music, unless you will receive an authorization from the musician who uploaded
                the music via MUZA's site and MUZA to continue to collaborate with him.{' '}
              </ListItem>
              <ListItem>
                3.5 you agree that downloading any track or music through MUZA site or platform
                doesn't allow you to make any commercial or public use of the music you've
                downloaded until you will receive authorization from the musician who uploaded the
                music.
              </ListItem>
              <ListItem>
                3.6 if you upload music, the musician who wishes to collaborate with you will send
                you through MUZA site or platform a request with a proposal that will include your
                music with a suggestion of a new arrangement and production. You can decide to allow
                the new collaboration or to notify the musician who wishes to collaborate that you
                are not interested to collaborate. If you decide to allow the collaboration, you and
                the other musician need to reach an agreement that will determine (among other
                things) ownership, mutual credits, profit allocation, expenses and royalties.{' '}
              </ListItem>
              <ListItem>
                3.7 if you downloaded music and was refused by the uploading musician, you agree
                that from the refusal you are forbidden from any public or commercial use.{' '}
              </ListItem>
              <ListItem>
                3.8 If you reached an agreement with another musician about a collaboration
                regarding the music you uploaded you will notify MUZA and remove the specific music
                from the site / platform.{' '}
              </ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              Updates and changes.
            </Typography>
            <Typography paragraph>
              In our sole discretion at any given time we may: Modify, update, add, delete, or
              discontinue these Terms or any aspect of the Site and the Services it provides, such
              changes will be effective immediately and incorporated into these Terms. Your
              continued use of the Website and/or the Services constitutes your absolute acceptance
              of such changes and the updated or modified terms. It is your full responsibility to
              regularly check for updates or changes to these Terms.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              Prohibited Activities
            </Typography>
            <Typography paragraph>
              You may not access or use the Website for any other purpose other than that for which
              the Company makes it available. The Website may not be used in connection with any
              commercial endeavors except those that are specifically endorsed or approved by the
              Company. Prohibited activity includes, but is not limited to:
            </Typography>
            <List sx={{ listStyleType: 'upper-Latin' }}>
              <ListItem sx={{ display: 'list-item' }}>
                attempting to bypass any measures of the Website designed to prevent or restrict
                access to the Website, or any portion of the Website.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                attempting to impersonate another user or person or using the username of another
                user.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>criminal or tortious activity.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                deciphering, decompiling, disassembling or reverse-engineering any of the software
                comprising or in any way making up a part of the Website
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                deleting the copyright or other proprietary rights notice from any Website content.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                engaging in any automated use of the system, such as using any data mining, robots,
                or similar data gathering and extraction tools.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                except as may be the result of a standard search engine or Internet browser usage,
                using or launching, developing or distributing any automated system, including,
                without limitation, any spider, robot (or "bot"), cheat utility, scraper or offline
                reader that accesses the Website or using or launching any unauthorized script or
                other software.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                harassing, annoying, intimidating, or threatening any Company employees or agents
                engaged in providing any portion of the Company Services to you.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                interfering with, disrupting, or creating an undue burden on the Website or the
                networks or services connected to the Website.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                making any unauthorized use of the Company Services, including collecting usernames
                and/or email addresses of users by electronic or other means for the purpose of
                sending unsolicited email, or creating user accounts by automated means or under
                false pretenses.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                selling or otherwise transferring your profile.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                systematic retrieval of data or other content from the Website to create or compile,
                directly or indirectly, a collection, compilation, database or directory without
                written permission from Company.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                tricking, defrauding or misleading Company and other users, especially in any
                attempt to learn sensitive account information such as passwords.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                using any information obtained from the Website in order to harass, abuse, or harm
                another person.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                using the Company Services as part of any effort to compete with the Company or to
                provide services as a service bureau.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                using the Website in a manner inconsistent with any and all applicable laws and
                regulations.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                you are restricted to using the music only in accordance with the license agreement
                and terms of use.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                provides any information that is false, misleading, inaccurate, or incomplete.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                you have to comply with the copyright owner's right and never use the music
                commercially or publicly without consent.
              </ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              USER DATA
            </Typography>
            <Typography paragraph>
              Our Website will maintain certain data that you transfer to the Website for the
              purpose of the performance of the Company Services, as well as data relating to your
              use of the Company Services. Although we perform regular routine backups of data, you
              are primarily responsible for all data that you have transferred or that relates to
              any activity you have undertaken using the Company Services. You agree that Company
              shall have no liability to you for any loss or corruption of any such data, and you
              hereby waive any right of action against Company arising from any such loss or
              corruption of such data.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              INTELLECTUAL PROPERTY RIGHTS
            </Typography>
            <Typography paragraph>
              The content on the Website (“Company Content”) and the trademarks, service marks and
              logos contained therein (“Marks”) are owned by or licensed to Company, and are subject
              to copyright intellectual property rights. Company Content, includes, without
              limitation, all source code, databases, functionality, software, website designs,
              audio, video, text, photographs, and graphics. All Company graphics, logos, designs,
              page headers, button icons, scripts and service names are registered trademarks,
              common law trademarks or trade dress of Company in the United States and/or other
              countries. Company's trademarks and trade dress may not be used, including as part of
              trademarks and/or as part of domain names, in connection with any product or service
              in any manner that is likely to cause confusion and may not be copied, imitated, or
              used, in whole or in part, without the prior written permission of the Company.
              Company Content on the Website is provided to you “AS IS” for your information and
              personal use only and may not be used, copied, reproduced, aggregated, distributed,
              transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any
              other purposes whatsoever without the prior written consent of the respective owners.
              Provided that you are eligible to use the Website, you are granted a limited license
              to access and use the Website and the Company Content and to download or print a copy
              of any portion of the Company Content to which you have properly gained access solely
              for your personal, non-commercial use. Company reserves all rights not expressly
              granted to you in and to the Website and Company Content and Marks.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              SITE MANAGEMENT
            </Typography>
            <Typography paragraph>
              Company reserves the right but does not have the obligation to: monitor the Website
              for violations of this Agreement; take appropriate legal action against anyone who, in
              Company’s sole discretion, violates this Agreement, including without limitation,
              reporting such user to law enforcement authorities; in Company’s sole discretion and
              without limitation, refuse, restrict access to or availability of, or disable (to the
              extent technologically feasible) any user’s contribution or any portion thereof that
              may violate this Agreement or any Company policy; in Company’s sole discretion and
              without limitation, notice or liability to remove from the Website or otherwise
              disable all files and content that are excessive in size or are in any way burdensome
              to Company’s systems; otherwise manage the Website in a manner designed to protect the
              rights and property of the Company and others and to facilitate the proper functioning
              of the Website. MUZA will allow musicians to upload their music with restrictions that
              will change from time to time, but will be specified in the site.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              PRIVACY POLICY
            </Typography>
            <Typography paragraph>
              We care about the privacy of our users. Please review the Company's Privacy Policy. By
              using the Website or Company Services, you are consenting to have your personal data
              transferred to and processed in the United States. By using the Website or the Company
              Services, you are consenting to the terms of our Privacy Policy.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              TERM AND TERMINATION
            </Typography>
            <Typography paragraph>
              This Agreement shall remain in full force and effect while you use the Website or are
              otherwise a user or member of the Website, as applicable. You may terminate your use
              or participation at any time, for any reason, by following the instructions for
              terminating user accounts in your account settings, if available, or by contacting us
              using the contact information below.
            </Typography>
            <Typography paragraph>
              WITHOUT LIMITING ANY OTHER PROVISION OF THIS AGREEMENT, COMPANY RESERVES THE RIGHT TO,
              IN COMPANY’S SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE
              OF THE WEBSITE AND THE COMPANY SERVICES, TO ANY PERSON FOR ANY REASON OR FOR NO REASON
              AT ALL, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY OR
              COVENANT CONTAINED IN THIS AGREEMENT, OR OF ANY APPLICABLE LAW OR REGULATION, AND
              COMPANY MAY TERMINATE YOUR USE OR PARTICIPATION IN THE WEBSITE AND THE COMPANY
              SERVICES, DELETE YOUR PROFILE AND ANY CONTENT OR INFORMATION THAT YOU HAVE POSTED AT
              ANY TIME, WITHOUT WARNING, IN COMPANY’S SOLE DISCRETION.
            </Typography>
            <Typography paragraph>
              In order to protect the integrity of the Website and Company Services, Company
              reserves the right at any time in its sole discretion to block certain IP addresses
              from accessing the Website and Company Services.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              CORRECTIONS
            </Typography>
            <Typography paragraph>
              Occasionally there may be information on the Website that contains typographical
              errors, inaccuracies, or omissions that may relate to service descriptions, pricing,
              availability, and various other information. Company reserves the right to correct any
              errors, inaccuracies or omissions and to change or update the information at any time,
              without prior notice.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              DISCLAIMERS
            </Typography>
            <Typography paragraph>
              YOU EXPRESSLY AGREE THAT YOUR USE OF THE WEBSITE AND COMPANY SERVICES WILL BE AT YOUR
              SOLE RISK. THEY ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE EXPRESSLY
              DISCLAIM ALL WARRANTIES OF ANY KIND, TO THE FULLEST EXTENT PERMITTED BY LAW, COMPANY,
              ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR
              IMPLIED, IN CONNECTION WITH THE WEBSITE AND THE COMPANY SERVICES AND YOUR USE THEREOF,
              INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
              A PARTICULAR PURPOSE AND NON-INFRINGEMENT. COMPANY MAKES NO WARRANTIES OR
              REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE WEBSITE’S CONTENT OR THE
              CONTENT OF ANY WEBSITES LINKED TO THIS WEBSITE AND ASSUMES NO LIABILITY OR
              RESPONSIBILITY FOR ANY (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
              (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
              ACCESS TO AND USE OF OUR WEBSITE, (C) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
              SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
              THEREIN, (D) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE OR
              COMPANY SERVICES, (E) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
              TRANSMITTED TO OR THROUGH THE WEBSITE BY ANY THIRD PARTY, AND/OR (F) ANY ERRORS OR
              OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
              AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
              VIA THE WEBSITE. COMPANY DOES NOT WARRANT, END
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              Liability
            </Typography>
            <Typography paragraph>
              Except as provided herein and to the fullest extent permitted by law, neither MUZA nor
              its suppliers are liable for any damages whatsoever and HAVE NO LIABILITY TO YOU FOR
              ANY DIRECT, INDIRECT OR CONSEQUENTIAL LOSS OR DAMAGE INCURRED BY YOU IN CONNECTION
              WITH THE USE OF THE SITE OR THE MUSIC INCLUDING LOSS OF INCOME; LOSS OF PROFITS OR
              CONTRACTS; LOSS OF BUSINESS; LOSS OF ANTICIPATED SAVINGS; LOSS OF BUSINESS
              OPPORTUNITY, GOODWILL OR REPUTATION; WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
              BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. Because some jurisdictions do
              not allow the exclusion or limitation of liability for consequential or incidental
              damages, the above limitation may not apply to you.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="h5" gutterBottom>
              INDEMNITY
            </Typography>
            <Typography paragraph>
              By using the Site or Services, to the extent permitted by law, You agree to defend,
              indemnify and hold MUZA and their respective officers, agents, partners, contractors,
              affiliates and licensors employees, harmless from and against, any loss, damage,
              liability, claim, or demand, including reasonable attorneys’ fees and expenses, made
              by any third party with respect to:(a) any claims arising out of your breach of these
              Terms, (b) your use of the Site or Services, (c) Company reserves the right, at your
              expense, to assume the exclusive defense and control of any matter for which you are
              required to indemnify Company, and you agree to cooperate, at your expense, with
              Company’s defense of such claims.
            </Typography>
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Got any Questions?
        </Typography>
        <Typography paragraph>
          If you have any questions (or comments) concerning this Terms Of Use, please email us at{' '}
          <a href="mailto:support@muza.studio" style={{ color: '#f1066c' }}>
            support@muza.studio
          </a>
        </Typography>
      </Paper>
    </Container>
  );
}
