import axios from 'axios';
import { useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import {
  Box,
  Button,
  Paper,
  Typography,
  Autocomplete,
  TextField,
  Divider,
  Alert
} from '@mui/material';
import { useTrackSubmission } from '../../context/TrackSubmissionContext';
import UploadProgress from '../UploadProgress';
import ZipDropZone from '../ZipDropZone';
import { useSnackBarContext } from '../../context/SnackBarContext';

interface Part {
  ETag: string;
  PartNumber: number;
}

export default function ArtistSubmissionPanel() {
  const { isSubmissionPanelOpen, downloadedTracks } = useTrackSubmission();
  const [zip, setZip] = useState<File | null>(null);
  const [notes, setNotes] = useState<string>('');
  const [trackId, setTrackId] = useState<number>();
  const [errors, setErrors] = useState({ zip: '' });
  const [zipUploadProgress, setZipUploadProgress] = useState<number>(0);
  const [isZipUploaded, setIsZipUploaded] = useState<boolean>(false);
  const [isTrackSubmitted, setIsTrackSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { createSnackBar } = useSnackBarContext();

  const confirm = useConfirm();

  const uploadFileInChunks = async (file: File, trackId: number, chunkSize = 10 * 1024 * 1024) => {
    const totalChunks = Math.ceil(file.size / chunkSize);
    const file_name = file.name;
    setIsLoading(true);
    try {
      const initResponse = await axios.post(
        `/api/track_downloads/${trackId}/initiate_multipart_upload`,
        new URLSearchParams({ file_name })
      );
      const { uploadId } = initResponse.data;

      const parts: Part[] = [];
      let uploadedSize = 0;

      for (let chunkNumber = 1; chunkNumber <= totalChunks; chunkNumber++) {
        const start = (chunkNumber - 1) * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append('file_name', file_name);
        formData.append('chunk', chunk);
        formData.append('chunk_index', chunkNumber.toString());
        formData.append('upload_id', uploadId);

        const response = await axios.put(
          `/api/track_downloads/${trackId}/upload_zip_chunk`,
          formData
        );

        parts.push({ ETag: response.data.ETag, PartNumber: chunkNumber });
        uploadedSize += chunk.size;

        setZipUploadProgress(Math.round((uploadedSize / file.size) * 100));
      }

      await axios.post(
        `/api/track_downloads/${trackId}/complete_multipart_upload`,
        new URLSearchParams({
          file_name,
          upload_id: uploadId,
          parts: JSON.stringify(parts),
          notes
        })
      );

      setIsTrackSubmitted(true);

      createSnackBar({
        content: 'Track submitted successfully',
        autoHide: true,
        severity: 'success'
      });
    } catch (error) {
      console.error('Error during file upload:', error);
      setIsTrackSubmitted(false);
      setErrors({ ...errors, zip: 'Error uploading zip file' });

      createSnackBar({
        content: 'An error occurred. Please try again later.',
        autoHide: true,
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
      setIsZipUploaded(true);
    }
  };

  const handleFileUpload = (acceptedFiles: File[]) => {
    setErrors({ ...errors, zip: '' });
    setZip(acceptedFiles[0]);
  };

  const onZipUploadCancel = () => {
    confirm({
      description: 'Are you sure you want to cancel uploading this zip file?',
      title: 'Cancel Upload',
      confirmationText: 'Yes',
      cancellationText: 'No'
    })
      .then(() => {
        setZip(null);
      })
      .catch(() => {
        console.log('cancelled');
      });
  };

  const getOptionLabel = (option: any) => option.track.name;
  const renderOption = (props: any, option: any) => (
    <li {...props} key={option.id}>
      {option.track.name}
    </li>
  );

  if (isTrackSubmitted)
    return (
      <Paper sx={{ mt: 2 }}>
        <Box sx={{ p: 3, width: '100%' }}>
          <Typography sx={{ textAlign: 'center', my: 8 }} variant="h5" gutterBottom>
            Your track has been submitted successfully!
          </Typography>
        </Box>
      </Paper>
    );

  return isSubmissionPanelOpen ? (
    <Paper sx={{ mt: 2 }}>
      <Box sx={{ p: 3, width: '100%' }}>
        <Typography sx={{ textAlign: 'center' }} variant="h5" gutterBottom>
          Submit a track
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', mt: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" color="#ffffff7f" sx={{ mt: 1 }} gutterBottom>
              Select a track from your downloaded tracks to submit.
            </Typography>
            <Autocomplete
              sx={{ mt: 2 }}
              options={downloadedTracks ?? []}
              getOptionLabel={getOptionLabel}
              renderOption={renderOption}
              renderInput={(params) => (
                <TextField {...params} required label="Select a track" variant="outlined" />
              )}
              onChange={(event, value) => {
                if (value) {
                  setTrackId(value.track.id);
                }
              }}
            />
            <Typography variant="subtitle1" color="#ffffff7f" sx={{ mt: 3 }} gutterBottom>
              Please provide notes about the track you are submitting.
            </Typography>
            <TextField
              sx={{ width: '100%' }}
              onChange={(e) => setNotes(e.target.value)}
              label="Notes about the track"
              minRows={3}
              multiline
            />
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 4 }} />
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 2 }} color="#ffffff7f">
              The zip file should contain all the stems of your track.
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Project files
            </Typography>
            <ZipDropZone onFileUpload={handleFileUpload} />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              {zip && (
                <UploadProgress
                  fileName={zip.name}
                  fileSize={zip.size}
                  progress={zipUploadProgress}
                  onCancel={onZipUploadCancel}
                  uploaded={zipUploadProgress === 100 && isZipUploaded}
                  uploadingAfterSubmit={isLoading}
                  uploadAfter
                />
              )}
            </Box>
            {errors.zip && (
              <Alert sx={{ my: 1 }} variant="outlined" severity="error">
                {errors.zip}
              </Alert>
            )}
          </Box>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{ fontSize: 18, mt: 4, mx: 'auto' }}
            variant="contained"
            color="primary"
            onClick={async () => {
              if (!trackId) return;
              if (!zip) return;
              await uploadFileInChunks(zip, trackId);
            }}
            disabled={isLoading || !zip || !trackId}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Paper>
  ) : null;
}
