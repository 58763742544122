import { FormEventHandler, useState } from 'react';
import { Button, TextField, Typography, Container, Box, Paper } from '@mui/material';
import { useMutation } from 'react-query';
import { AuthService } from '../client';
import { useSnackBarContext } from '../context/SnackBarContext';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function ResetPasswordPage() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { createSnackBar } = useSnackBarContext();

  const token = searchParams.get('token') || '';

  const resetPasswordMutation = useMutation<
    boolean,
    Error,
    { token: string; new_password: string }
  >(
    ({ token, new_password }) =>
      AuthService.resetPasswordApiAuthResetPasswordPost(token, new_password),
    {
      onSuccess: () => {
        createSnackBar({
          content: 'Password reset successfully.',
          autoHide: true,
          severity: 'success'
        });
        setError('');
        navigate('/login');
      }
    }
  );

  const handleForgotPassword: FormEventHandler = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters');
      return;
    }
    try {
      await resetPasswordMutation.mutateAsync({ token, new_password: newPassword });
    } catch (error) {
      createSnackBar({
        content: 'Error resetting password.',
        autoHide: true,
        severity: 'error'
      });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: 15
      }}
    >
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography component="h1" variant="h5" sx={{ display: 'flex', gap: 1 }}>
          Reset Password
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleForgotPassword}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            id="newPassword"
            autoComplete="current-password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="current-password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            size="large"
            disabled={resetPasswordMutation.isLoading}
          >
            Confirm
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </Paper>
    </Container>
  );
}
