import { Box, Container, Typography } from '@mui/material';
import BreadcrumbsPanel from '../../components/BreadcrumbsPanel';
import { useLocation } from 'react-router-dom';
import { BillingSettings } from '../../components/Billing/BillingSettings';

export default function ProfileBilling() {
  const location = useLocation();
  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <BreadcrumbsPanel breadcrumbs={[{ label: 'Profile Billing', href: location.pathname }]} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Billing
        </Typography>
      </Box>
      <BillingSettings />
    </Container>
  );
}
