import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface PlanType {
  planName: ReactNode;
  monthlyPrice: string;
  annualPrice: string;
}

export interface FeatureType {
  optionName: string;
  basic: number | boolean | string;
  advanced: number | boolean | string;
  pro: number | boolean | string;
}

interface PlanCardProps {
  plan: PlanType;
  isAnnual: boolean;
}

const PlanCard: React.FC<PlanCardProps> = ({ plan, isAnnual }) => (
  <Box>
    <Typography
      variant="h5"
      component="h2"
      textAlign="center"
      fontSize={{ xs: '1rem', md: '1.25rem' }}
    >
      {plan.planName}
    </Typography>
    <Typography
      variant="h6"
      color="text.secondary"
      textAlign="center"
      fontSize={{ xs: '0.75rem', md: '1rem' }}
    >
      {isAnnual ? `${plan.annualPrice} / yr` : `${plan.monthlyPrice} / mo`}
    </Typography>
  </Box>
);

export default PlanCard;
