/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentsService {

    /**
     * Create Checkout Session
     * @param plan
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createCheckoutSessionApiPaymentsCreateCheckoutSessionPost(
        plan: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/create-checkout-session',
            query: {
                'plan': plan,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Subscription
     * @param plan
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateSubscriptionApiPaymentsUpdateSubscriptionPost(
        plan: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/update-subscription',
            query: {
                'plan': plan,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Webhook Received
     * @returns any Successful Response
     * @throws ApiError
     */
    public static webhookReceivedApiPaymentsWebhookPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/webhook',
        });
    }

    /**
     * Current Subscription
     * @returns any Successful Response
     * @throws ApiError
     */
    public static currentSubscriptionApiPaymentsCurrentSubscriptionGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/current-subscription',
        });
    }

    /**
     * Cancel Subscription
     * @returns any Successful Response
     * @throws ApiError
     */
    public static cancelSubscriptionApiPaymentsCancelSubscriptionGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/cancel-subscription',
        });
    }

    /**
     * Unsubscribe Feedback
     * @param feedback
     * @returns any Successful Response
     * @throws ApiError
     */
    public static unsubscribeFeedbackApiPaymentsUnsubscribeFeedbackPost(
        feedback: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/unsubscribe/feedback',
            query: {
                'feedback': feedback,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
