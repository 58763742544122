import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthService, CurrentUserResponse } from '../client';

interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  currentUser?: CurrentUserResponse;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: currentUser, isLoading } = useQuery(
    'currentUser',
    AuthService.currentUserApiAuthCurrentGet,
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  );

  const isAuthenticated = !!currentUser?.email;

  return (
    <AuthContext.Provider value={{ isAuthenticated, currentUser, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return authContext;
};
