import { Container, Typography, List, ListItem } from '@mui/material';
import { useLocation } from 'react-router-dom';
import BreadcrumbsPanel from '../components/BreadcrumbsPanel';

export default function PrivacyPolicy() {
  const location = useLocation();
  return (
    <Container maxWidth="md" sx={{ mt: 2, mb: 10 }}>
      <BreadcrumbsPanel breadcrumbs={[{ label: 'Privacy Policy', href: location.pathname }]} />

      <Typography variant="h2" gutterBottom>
        Muza Privacy Policy
      </Typography>

      <Typography variant="h6">Effective Date: Nov 12, 2023</Typography>

      <Typography paragraph>
        Welcome to Muza. This Privacy Policy explains how we collect, use, disclose, and safeguard
        your information when you visit our website and use our service, which offers
        recommendations for optimizing AWS Athena queries. We respect your privacy and are committed
        to protecting it through our compliance with this policy.
      </Typography>

      <Typography variant="h5">Collection of Information</Typography>
      <List>
        <ListItem>
          <Typography>
            <strong>Email and Registration Data:</strong> We collect your email address when you
            sign up for our service.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            <strong>AWS Account Access:</strong> To use our service, you must provide access to your
            AWS account. We only access metadata related to your Athena queries and do not read
            actual data.
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h5">Use of Information</Typography>
      <List>
        <ListItem>
          <Typography>
            The information we collect is used solely for the purpose of providing and improving our
            service, which includes analyzing and offering recommendations for optimizing AWS Athena
            queries.
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h5">Disclosure of Information</Typography>
      <List>
        <ListItem>
          <Typography>
            We do not sell, rent, or otherwise share your personal information with third parties
            for their marketing purposes.
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h5">Data Security</Typography>
      <List>
        <ListItem>
          <Typography>
            We implement a variety of security measures to maintain the safety of your personal
            information when you enter, submit, or access your personal information.
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h5">Changes to This Privacy Policy</Typography>
      <Typography paragraph>
        We may update our Privacy Policy from time to time. We will notify you of any changes by
        posting the new Privacy Policy on this page. You are advised to review this Privacy Policy
        periodically for any changes.
      </Typography>

      <Typography variant="h5">Contact Us</Typography>
      <Typography paragraph>
        If you have any questions about this Privacy Policy, please contact us.
      </Typography>
    </Container>
  );
}
