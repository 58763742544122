import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, styled } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';

type ImageDropZoneProps = {
  onImageUpload: (acceptedFiles: File[]) => void;
};

const ImageDragZoneBox = styled(Box)({
  border: '2px dashed rgba(255, 255, 255, 0.2)',
  borderRadius: '10px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)'
  }
});

const ImageDropZone = ({ onImageUpload }: ImageDropZoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onImageUpload(acceptedFiles);
    },
    [onImageUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png', '.gif', '.svg']
    }
  });

  return (
    <ImageDragZoneBox {...getRootProps()}>
      <input {...getInputProps()} />
      <ImageSearchIcon sx={{ fontSize: 40, color: 'action.active' }} />
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        {isDragActive ? 'Drop the images here ...' : 'Click to upload or drag and drop'}
      </Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        jpg, png, svg or gif (max. 5MB)
      </Typography>
    </ImageDragZoneBox>
  );
};

export default ImageDropZone;
