import UpcomingWizard from '../../../components/UpcomingProjects/UpcomingWizard';
import { MusicUploadProvider } from '../../../context/MusicUploadContext';
import { UpcomingProjectProvider } from '../../../context/UpcomingProjectContext';

export default function ProfileUpcomingAdd() {
  return (
    <UpcomingProjectProvider>
      <MusicUploadProvider>
        <UpcomingWizard />
      </MusicUploadProvider>
    </UpcomingProjectProvider>
  );
}
