import React, { FormEventHandler, useState } from 'react';
import { Button, TextField, Typography, Container, Box, Paper } from '@mui/material';
import { useMutation } from 'react-query';
import { AuthService } from '../client';
import { useSnackBarContext } from '../context/SnackBarContext';

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const { createSnackBar } = useSnackBarContext();

  const forgotPasswordMutation = useMutation(AuthService.forgotPasswordApiAuthForgotPasswordPost, {
    onSuccess: () => {
      createSnackBar({
        content: 'Password reset email sent.',
        autoHide: true,
        severity: 'success'
      });
    }
  });

  const handleForgotPassword: FormEventHandler = async (event) => {
    event.preventDefault();
    try {
      await forgotPasswordMutation.mutateAsync(email);
    } catch (error) {
      createSnackBar({
        content: 'Error resetting password.',
        autoHide: true,
        severity: 'error'
      });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: 15
      }}
    >
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography component="h1" variant="h5" sx={{ display: 'flex', gap: 1 }}>
          Reset Password
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleForgotPassword}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            size="large"
            disabled={forgotPasswordMutation.isLoading}
          >
            Reset My Password
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
