import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Avatar, Box } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

interface UploadAvatarProps {
  onImageSelect: (image: File) => void;
  selectedImage: string | null;
}

export const UploadAvatar = ({ onImageSelect, selectedImage }: UploadAvatarProps): JSX.Element => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onImageSelect(acceptedFiles[0]);
    },
    [onImageSelect]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png', '.svg']
    }
  });

  const getImageSrc = (image: File | string) => {
    return image instanceof File ? URL.createObjectURL(image) : image;
  };

  return (
    <label
      style={{
        width: '100%',
        height: 0,
        paddingBottom: '100%',
        display: 'block',
        position: 'relative',
        background: '#000000f'
      }}
      {...getRootProps()}
      onClick={(e) => e.stopPropagation()}
    >
      <input {...getInputProps()} />
      {selectedImage && (
        <Avatar
          src={getImageSrc(selectedImage)}
          sx={{ width: '100%', height: '100%', position: 'absolute' }}
          alt="profile"
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          bottom: 0,
          right: 0,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          background: '#0000007f',
          opacity: !selectedImage ? 0.6 : 0,
          '&:hover': {
            opacity: 0.7
          },
          transition: 'opacity 0.3s'
        }}
      >
        <ModeEditIcon sx={{ fontSize: 44 }} />
      </Box>
    </label>
  );
};
