import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';

export default function ProtectedRoute({ redirectPath = '/login' }) {
  let { isAuthenticated, isLoading } = useAuth();
  let location = useLocation();

  if (isLoading) {
    return null;
  }
  if (!isAuthenticated) {
    return <Navigate to={`${redirectPath}?next=${location.pathname}`} replace />;
  }

  return <Outlet />;
}
