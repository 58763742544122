import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Track, TrackGenre, TrackInfoUpdateRequest, TracksService } from '../client';
import {
  audioUploadSchema,
  bpmAndScaleSchema,
  imageUploadSchema,
  trackInformationSchema,
  zipUploadSchema
} from '../components/MusicUploadWizard/UploadSchema';

interface MusicUploadContextProps {
  activeStep: number;
  setActiveStep: (step: number) => void;
  track: Track | null;
  setTrack: (track: Track | null) => void;
  handleNext: () => void;
  handleBack: () => void;
  apiErrors: any;
  setApiErrors: (errors: any) => void;
  formMethods: UseFormReturn<FieldValues, any, FieldValues>;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

export const MusicUploadContext = createContext<MusicUploadContextProps | undefined>(undefined);

export function useMusicUpload() {
  const context = useContext(MusicUploadContext);
  if (!context) {
    throw new Error('useMusicUpload must be used within a MusicUploadProvider');
  }
  return context;
}

export function MusicUploadProvider({ children }: { children: React.ReactNode }) {
  const [activeStep, setActiveStep] = useState(0);
  const [track, setTrack] = useState<any>(null);
  const [apiErrors, setApiErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const currentSchema = useMemo(() => {
    switch (activeStep) {
      case 0:
        return audioUploadSchema;
      case 1:
        return zipUploadSchema;
      case 2:
        return trackInformationSchema;
      case 3:
        return imageUploadSchema;
      case 4:
        return bpmAndScaleSchema;
      default:
        return yup.object().shape({});
    }
  }, [activeStep]);

  useEffect(() => {
    async function trigger() {
      await formMethods.trigger();
    }
    trigger();
  }, [currentSchema]);

  const formMethods = useForm({
    resolver: yupResolver(currentSchema),
    mode: 'onChange'
  });

  const watchFormData = formMethods.watch() as TrackInfoUpdateRequest;

  useEffect(() => {
    if (track) {
      console.log('resetting form:', track);
      formMethods.reset({
        ...formMethods.getValues(),
        ...track,
        genres: track.genres.map((g: TrackGenre) => g.name),
      });
    }
  }, [track, formMethods]);

  const trackInfoMutation = useMutation<Track, Error, TrackInfoUpdateRequest>(
    (requestBody) => TracksService.updateTrackInfoApiTracksTrackIdPut(track?.id, requestBody),
    {
      onSuccess: () => {
        console.log('success');
      },
      onError: async (error: any) => {
        console.log(error);
      }
    }
  );

  const handleNext = async () => {
    switch (activeStep) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        if (track) {
          await trackInfoMutation.mutateAsync({
            name: watchFormData.name,
            about: watchFormData.about,
            genres: watchFormData?.genres || []
          });
        }
        break;
      case 4:
        if (track) {
          await trackInfoMutation.mutateAsync({
            bpm: watchFormData.bpm,
            scale: watchFormData.scale
          });
        }
        break;
      default:
        break;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <MusicUploadContext.Provider
      value={{
        activeStep,
        setActiveStep,
        track,
        setTrack,
        handleNext,
        handleBack,
        apiErrors,
        setApiErrors,
        formMethods,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </MusicUploadContext.Provider>
  );
}
