import { useQuery } from 'react-query';

import 'swiper/css';
import 'swiper/css/pagination';

import { Loader } from '../Loader';
import { TracksCarousel } from '../TracksCarousel';
import { TracksService } from '../../client';

export default function MostDownloadedTracks() {
  const { data: mostDownloadedTracks, isLoading } = useQuery(
    'mostDownloadedTracks',
    TracksService.getMostDownloadedTracksApiTracksGetMostDownloadedTracksGet,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!mostDownloadedTracks) {
    return null;
  }

  return <TracksCarousel tracks={mostDownloadedTracks} />;
}
