import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField
} from '@mui/material';
import { UploadAvatar } from '../../components/Profile/UploadAvatar';
import { AuthService, Body_update_profile_api_auth_profile_put } from '../../client';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Loader } from '../../components/Loader';
import { Controller } from 'react-hook-form';
import { useProfileForm } from '../../hooks/useProfileForm';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { useHideProfile } from '../../hooks/useHideProfile';

export default function ProfileSettings() {
  const queryClient = useQueryClient();
  const { data: profile, isLoading } = useQuery('profile', AuthService.profileApiAuthProfileGet);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useProfileForm();

  const hideProfileMutation = useHideProfile();

  const { createSnackBar } = useSnackBarContext();

  const updateProfileMutation = useMutation(
    (updatedUser: Body_update_profile_api_auth_profile_put) => {
      return AuthService.updateProfileApiAuthProfilePut(updatedUser);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('profile');
        createSnackBar({
          content: 'Profile has been updated successfully',
          autoHide: true,
          severity: 'success'
        });
      },
      onError: () => {
        createSnackBar({ content: 'Profile update failed', autoHide: true, severity: 'error' });
      }
    }
  );

  if (isLoading) {
    return <Loader />;
  }
  if (!profile) return <Box></Box>;

  const onSubmit = async (values: Partial<Body_update_profile_api_auth_profile_put>) => {
    if (typeof values.avatar === 'string') delete values.avatar;
    updateProfileMutation.mutate(values as Body_update_profile_api_auth_profile_put);
  };

  const handleImageSelect = (image: File | string) => {
    console.log('image:', image);
    if (image instanceof File) setValue('avatar', image);
  };

  const handleHideProfile = async () => {
    await hideProfileMutation.mutateAsync();
  };

  return (
    <Paper sx={{ p: 5 }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container>
          <Grid item xs={12} sm={2} md={3}>
            <Box sx={{ mr: { xs: 0, sm: 2, md: 4 }, mb: { xs: 4, sm: 0 } }}>
              <Controller
                name="avatar"
                control={control}
                defaultValue={profile.avatar_url || ''}
                render={({ field: { value } }) => (
                  <UploadAvatar onImageSelect={handleImageSelect} selectedImage={value} />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={10} md={9}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={4}
              width="100%"
              sx={{ mb: 4 }}
            >
              <Controller
                name="profile_name"
                control={control}
                defaultValue={profile.profile_name}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    error={!!errors.profile_name}
                    helperText={errors.profile_name?.message}
                    fullWidth
                    label="Profile Name"
                    margin="none"
                    required
                    value={value}
                    onChange={onChange}
                    sx={{ width: '100%' }}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                defaultValue={profile.email}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    fullWidth
                    label="Email"
                    margin="none"
                    required
                    value={value}
                    onChange={onChange}
                    sx={{ width: '100%' }}
                  />
                )}
              />
              <Controller
                name="personal_name"
                control={control}
                defaultValue={profile.personal_name}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    error={!!errors.personal_name}
                    helperText={errors.personal_name?.message}
                    fullWidth
                    label="Full Name"
                    margin="none"
                    required
                    value={value}
                    onChange={onChange}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="about"
              control={control}
              defaultValue={profile.about || ''}
              render={({ field: { value, onChange } }) => (
                <TextField
                  fullWidth
                  label="About"
                  margin="none"
                  value={value}
                  onChange={onChange}
                  rows={5}
                  multiline
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={4}
              width="100%"
              sx={{ my: 4 }}
            >
              <Controller
                name="instagram_url"
                control={control}
                defaultValue={profile.instagram_url || ''}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    error={!!errors.instagram_url}
                    helperText={errors.instagram_url?.message}
                    fullWidth
                    label="Instagram Link"
                    margin="none"
                    value={value}
                    onChange={onChange}
                    sx={{ width: '100%' }}
                  />
                )}
              />
              <Controller
                name="facebook_url"
                control={control}
                defaultValue={profile.facebook_url || ''}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    error={!!errors.facebook_url}
                    helperText={errors.facebook_url?.message}
                    fullWidth
                    label="Facebook Link"
                    margin="none"
                    value={value}
                    onChange={onChange}
                    sx={{ width: '100%' }}
                  />
                )}
              />
              <Controller
                name="spotify_url"
                control={control}
                defaultValue={profile.spotify_url || ''}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    error={!!errors.spotify_url}
                    helperText={errors.spotify_url?.message}
                    fullWidth
                    label="Spotify Link"
                    margin="none"
                    value={value}
                    onChange={onChange}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </Box>
            <FormControl sx={{ px: 1 }}>
              <FormControlLabel
                label="Hide Profile and Projects"
                control={
                  <Switch
                    defaultChecked={profile.is_hidden}
                    onChange={handleHideProfile}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || updateProfileMutation.isLoading}
              >
                {isSubmitting || updateProfileMutation.isLoading ? 'Saving...' : 'Save Changes'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
