export const genres = [
  'Afrobeat',
  'Alternative',
  'Ambient',
  'Bass',
  'Bollywood',
  'Blues',
  'Bluegrass',
  'Break beat / Breaks',
  'C-Pop',
  'Calypso',
  'Cajun',
  'Classical',
  'Chillout',
  'Country',
  'Disco',
  'Deep House',
  'Downtempo',
  'Drum and Bass',
  'Dubstep',
  'Electronic/Dance',
  'Electronica',
  'Electro',
  'Experimental',
  'Fado',
  'Flamenco',
  'Folk',
  'Funk',
  'Future House',
  'Gospel',
  'Gregorian Chant',
  'Grime',
  'Hard House',
  'Hip-Hop/Rap',
  'House',
  'Indie',
  'Indie dance',
  'J-Pop',
  'Jazz',
  'K-Pop',
  'Latin',
  'Melodic Techno',
  'Metal',
  'Minimal',
  'New Age',
  'Organic House',
  'Opera',
  'Punk',
  'Pop',
  'Progressive House',
  'Progressive trance',
  'Psy-Trance',
  'Qawwali',
  'R&B/Soul',
  'Reggae',
  'Reggaeton',
  'Rock',
  'Samba',
  'Ska',
  'Soundtrack',
  'Sufi Music',
  'Synth-pop',
  'Tango',
  'Techno',
  'Tech House',
  'Trance',
  'Trap',
  'World Music',
  'Zydeco',
  'Other'
];
