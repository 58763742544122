import React from 'react';
import { Box, Button, Container, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';

import { useLocation, useParams } from 'react-router-dom';
import { Track, TracksService } from '../../client';
import { useQuery } from 'react-query';
import { Loader } from '../Loader';
import BreadcrumbsPanel from '../BreadcrumbsPanel';
import { removeExtension } from '../../utils';
import CreateUpcomingTrack from './CreateUpcomingTrack';
import { useAddUpcomingProject } from '../../context/UpcomingProjectContext';
import { useMusicUpload } from '../../context/MusicUploadContext';
import UploadImage from './UploadImage';

const steps = ['Track Information', 'Image'];

export default function UpcomingWizard() {
  const { trackId } = useParams();
  const location = useLocation();

  const { isLoading: isDetailsLoading } = useQuery(
    `track-${trackId}`,
    () => TracksService.getTrackDetailsApiTracksTrackIdGet(Number(trackId) || -1),
    {
      enabled: !!trackId,
      onSuccess: (data: Track) => {
        setTrack(data);
        setTrackForm(data);
      }
    }
  );

  const { handleNext, handleBack, activeStep, track, setTrack, formMethods, isLoading } =
    useAddUpcomingProject();

  const { setTrack: setTrackForm } = useMusicUpload();

  const {
    formState: { isSubmitting, errors }
  } = formMethods;

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <CreateUpcomingTrack />;
      case 1:
        return <UploadImage />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <BreadcrumbsPanel
        breadcrumbs={[
          { label: 'Upcoming Projects', href: '/profile/upcoming-projects' },
          {
            label: trackId
              ? `Update upcoming ${removeExtension(track?.name || '')}`
              : 'Add Upcoming Project',
            href: location.pathname
          }
        ]}
      />

      <Typography variant="h4" gutterBottom>
        {trackId ? `Update upcoming ${removeExtension(track?.name || '')}` : 'Add Upcoming Project'}
      </Typography>
      <Paper sx={{ width: '100%', borderRadius: 3, px: 4, py: 4 }} variant="elevation">
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 4, mb: 1 }} variant="h6" color="#ffffffee" gutterBottom>
              Congratulations!
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 4 }} color="#ffffff7f">
              {trackId ? (
                <span>
                  Your track <b>{track?.name}</b> is now updated.
                </span>
              ) : (
                <span>
                  Your track <b>{track?.name}</b> is now uploaded.
                </span>
              )}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button href="/profile/upcoming-projects">Go to upcoming projects</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ mt: 4 }}>{!isDetailsLoading ? renderStepContent() : <Loader />}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0 || isSubmitting || isLoading}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                onClick={handleNext}
                disabled={isSubmitting || isLoading || (Object.keys(errors).length > 0 && !trackId)}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Paper>
    </Container>
  );
}
