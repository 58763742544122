export const musicalKeys = [
  'A major',
  'A minor',
  'A# major',
  'A# minor',
  'Ab major',
  'Ab minor',
  'B major',
  'B minor',
  'Bb major',
  'Bb minor',
  'C major',
  'C minor',
  'C# major',
  'C# minor',
  'D major',
  'D minor',
  'D# major',
  'D# minor',
  'Db major',
  'Db minor',
  'E major',
  'E minor',
  'F major',
  'F minor',
  'F# major',
  'F# minor',
  'Gb major',
  'Gb minor',
  'G major',
  'G minor',
  'G# major',
  'G# minor'
];
