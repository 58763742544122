import { useQuery } from 'react-query';
import { AuthService } from '../client';

export const useCurrentUser = () => {
  return useQuery('currentUser', AuthService.currentUserApiAuthCurrentGet, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
