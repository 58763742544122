/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Page_SearchResult_ } from '../models/Page_SearchResult_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SearchService {

    /**
     * Search
     * @param query
     * @param searchType
     * @param page
     * @param size
     * @returns Page_SearchResult_ Successful Response
     * @throws ApiError
     */
    public static searchApiSearchGet(
        query?: string,
        searchType: string = '',
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_SearchResult_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/search',
            query: {
                'query': query,
                'search_type': searchType,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
