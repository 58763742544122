import { useQuery } from 'react-query';
import { AuthService } from '../client';

export const useProfile = () => {
  return useQuery('profile', AuthService.profileApiAuthProfileGet, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
