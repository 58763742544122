import { useQuery } from 'react-query';
import { Controller } from 'react-hook-form';
import { Box, TextField, Typography } from '@mui/material';
import { Track, TracksService } from '../../client';
import { useAddUpcomingProject } from '../../context/UpcomingProjectContext';
import { useMusicUpload } from '../../context/MusicUploadContext';

export default function CreateUpcomingTrack() {
  const { track, setTrack, formMethods } = useAddUpcomingProject();
  const { setTrack: setTrackForm } = useMusicUpload();

  const { control } = formMethods;

  useQuery(
    `track-${track?.id}`,
    () => TracksService.getTrackDetailsApiTracksTrackIdGet(Number(track?.id) || -1),
    {
      enabled: !!track?.id,
      onSuccess: (data: Track) => {
        setTrack(data);
        setTrackForm(data);
      }
    }
  );

  return (
    <Box>
      <Typography variant="h6" color="#ffffffee" gutterBottom>
        Upcoming Track Information
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4 }} color="#ffffff7f">
        Provide the name and description which would best describe your track.
      </Typography>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field: { value, onChange } }) => (
          <TextField
            fullWidth
            label="Track Name"
            margin="none"
            required
            value={value}
            onChange={onChange}
          />
        )}
      />
    </Box>
  );
}
