import axios from 'axios';
import { useState } from 'react';
import { useConfirm } from 'material-ui-confirm';

import { Alert, Box, Typography } from '@mui/material';

import UploadProgress from '../UploadProgress';
import ZipDropZone from '../ZipDropZone';
import { useMusicUpload } from '../../context/MusicUploadContext';
import { Controller } from 'react-hook-form';

interface Part {
  ETag: string;
  PartNumber: number;
}

export default function UploadZip() {
  const { track, setTrack, apiErrors, setApiErrors, formMethods, setIsLoading } = useMusicUpload();
  const [zipUploadProgress, setZipUploadProgress] = useState<number>(0);
  const [isZipUploaded, setIsZipUploaded] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = formMethods;

  const zip_file = watch('zip_file');

  const confirm = useConfirm();

  const uploadFileInChunks = async (file: File, trackId: number, chunkSize = 10 * 1024 * 1024) => {
    const totalChunks = Math.ceil(file.size / chunkSize);
    const file_name = file.name;

    try {
      const initResponse = await axios.post(
        `/api/tracks/${trackId}/initiate_multipart_upload`,
        new URLSearchParams({ file_name })
      );
      const { uploadId } = initResponse.data;

      const parts: Part[] = [];
      let uploadedSize = 0;

      for (let chunkNumber = 1; chunkNumber <= totalChunks; chunkNumber++) {
        const start = (chunkNumber - 1) * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append('file_name', file_name);
        formData.append('chunk', chunk);
        formData.append('chunk_index', chunkNumber.toString());
        formData.append('upload_id', uploadId);

        const response = await axios.put(`/api/tracks/${trackId}/upload_zip_chunk`, formData);

        parts.push({ ETag: response.data.ETag, PartNumber: chunkNumber });
        uploadedSize += chunk.size;

        setZipUploadProgress(Math.round((uploadedSize / file.size) * 100));
      }

      const completeResponse = await axios.post(
        `/api/tracks/${trackId}/complete_multipart_upload`,
        new URLSearchParams({
          file_name,
          upload_id: uploadId,
          parts: JSON.stringify(parts)
        })
      );

      setTrack(completeResponse.data);
    } catch (error) {
      console.error('Error during file upload:', error);
    } finally {
      setIsLoading(false);
      setIsZipUploaded(true);
    }
  };

  const handleFileUpload = async (acceptedFiles: File[]) => {
    setIsLoading(true);
    setZipUploadProgress(0);
    setIsZipUploaded(false);
    setTrack(null);

    setValue('zip_file', acceptedFiles[0]);
    await handleSubmit(async (data) => {
      setApiErrors({ ...errors, zip_file: '' });
      await uploadFileInChunks(data.zip_file, data.id);
    })();
  };

  const onZipUploadCancel = () => {
    confirm({
      description: 'Are you sure you want to cancel uploading this zip file?',
      title: 'Cancel Upload',
      confirmationText: 'Yes',
      cancellationText: 'No'
    })
      .then(() => {
        reset({ zip_file: null });
        setIsZipUploaded(false);
        setZipUploadProgress(0);
        setIsLoading(false);
      })
      .catch(() => {
        console.log('cancelled');
      });
  };

  return (
    <Box>
      <Typography variant="h6" color="#ffffffee" gutterBottom>
        Upload Zip File
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4 }} color="#ffffff7f">
        The zip file should contain all the stems of your track.
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Zip File
      </Typography>
      <Controller
        name="zip_file"
        control={control}
        defaultValue={null}
        render={({ field: { value } }) => (
          <>
            <ZipDropZone onFileUpload={handleFileUpload} />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              {(zip_file || track?.data_url) && (
                <UploadProgress
                  fileName={zip_file?.name || track?.data_url?.split('/').pop() || ''}
                  fileSize={zip_file?.size}
                  progress={!!track?.data_url ? 100 : zipUploadProgress}
                  onCancel={onZipUploadCancel}
                  uploaded={(zipUploadProgress === 100 && isZipUploaded) || !!track?.data_url}
                  url={zipUploadProgress === 0 ? track?.data_url : undefined}
                />
              )}
            </Box>
          </>
        )}
      />
      {apiErrors.zip && (
        <Alert sx={{ my: 1 }} variant="outlined" severity="error">
          {apiErrors.zip}
        </Alert>
      )}
      {errors.zip_file && errors.zip_file.type === 'FILE_SIZE' && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {String(errors.zip_file.message)}
        </Typography>
      )}
    </Box>
  );
}
