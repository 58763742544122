import { Box, Container, Typography } from '@mui/material';

import ProfileDownloadsList from '../../../components/ProfileDownloadsList';
import BreadcrumbsPanel from '../../../components/BreadcrumbsPanel';
import { useLocation } from 'react-router-dom';

export default function ProfileDownloads() {
  const location = useLocation();
  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <BreadcrumbsPanel breadcrumbs={[{ label: "Downloads", href: location.pathname }]} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Downloads
        </Typography>
      </Box>
      <ProfileDownloadsList />
    </Container>
  );
}
